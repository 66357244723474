/* tslint:disable */
/* eslint-disable */

import { Response } from './pim.types.custom';
import { RequestOptions } from './pim.types.custom';
import { UUID } from './pim.types.custom';
import 'reflect-metadata';

export interface HttpClient {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: RequestOptions; }): RestResponse<R>;
}

export class AccountEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/account/additional-gtc
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.acceptAdditionalGTC
     */
    acceptAdditionalGTC(options?: RequestOptions): RestResponse<Payload<GTCStatus>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/additional-gtc`, options: options });
    }

    /**
     * HTTP GET /v1/account/additional-gtc
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getAdditionalGTCStatus
     */
    getAdditionalGTCStatus(options?: RequestOptions): RestResponse<Payload<GTCStatus>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/additional-gtc`, options: options });
    }

    /**
     * HTTP POST /v1/account/allowlist
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.addCompanyToAllowlist
     */
    addCompanyToAllowlist(data: CompanyData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/allowlist`, data: data, options: options });
    }

    /**
     * HTTP DELETE /v1/account/allowlist
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.removeCompanyFromAllowlist
     */
    removeCompanyFromAllowlist(data: CompanyData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/account/allowlist`, data: data, options: options });
    }

    /**
     * HTTP DELETE /v1/account/billing-address
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.deleteBillingAddress
     */
    deleteBillingAddress(options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/account/billing-address`, options: options });
    }

    /**
     * HTTP GET /v1/account/billing-address
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getBillingAddress
     */
    getBillingAddress(options?: RequestOptions): RestResponse<Payload<AddressLocationData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/billing-address`, options: options });
    }

    /**
     * HTTP POST /v1/account/billing-address
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateBillingAddress
     */
    updateBillingAddress(data: AddressLocationData, options?: RequestOptions): RestResponse<Payload<AddressLocationData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/billing-address`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/account/blocklist
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.addCompanyToBlacklist
     */
    addCompanyToBlacklist(data: CompanyData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/blocklist`, data: data, options: options });
    }

    /**
     * HTTP DELETE /v1/account/blocklist
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.removeCompanyFromBlocklist
     */
    removeCompanyFromBlocklist(data: CompanyData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/account/blocklist`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/account/companies/
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.listAllCompanies
     */
    listAllCompanies(queryParams?: { companyName?: string; }, options?: RequestOptions): RestResponse<Payload<CompanyData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/companies/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/account/company
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getCompanyInfo
     */
    getCompanyInfo(queryParams?: { companyId?: UUID; }, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/company`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/account/company
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateCompany
     */
    updateCompany(data: CompanyData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/company`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/account/create-user
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.createUser
     */
    createUser(user: UserData, options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/create-user`, data: user, options: options });
    }

    /**
     * HTTP DELETE /v1/account/credit-card
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.deleteCreditCard
     */
    deleteCreditCard(queryParams?: { id?: UUID; }, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/account/credit-card`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/account/credit-card
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateCreditCard
     */
    updateCreditCard(data: CreditCardData, options?: RequestOptions): RestResponse<Payload<CreditCardData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/credit-card`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/account/forgot-password
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.forgotPassword
     */
    forgotPassword(user: UserData, options?: RequestOptions): RestResponse<Payload<{ [index: string]: string }>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/forgot-password`, data: user, options: options });
    }

    /**
     * HTTP DELETE /v1/account/general-terms
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.deleteGeneralTerms
     */
    deleteGeneralTerms(options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/account/general-terms`, options: options });
    }

    /**
     * HTTP GET /v1/account/general-terms
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getGeneralTerms
     */
    getGeneralTerms(queryParams?: { companyId?: UUID; legId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobMetaData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/general-terms`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/account/general-terms
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.uploadGeneralTerms
     */
    uploadGeneralTerms(data: BlobData, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/general-terms`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/account/impersonate
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.impersonateCompany
     */
    impersonateCompany(req: ImpersonateCompanyRequestData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/impersonate`, data: req, options: options });
    }

    /**
     * HTTP POST /v1/account/impersonate/reset
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.resetImpersonatedCompany
     */
    resetImpersonatedCompany(options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/impersonate/reset`, options: options });
    }

    /**
     * HTTP GET /v1/account/notificationsettings/
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getNotificationSettings
     */
    getNotificationSettings(options?: RequestOptions): RestResponse<Payload<NotificationSettingsData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/notificationsettings/`, options: options });
    }

    /**
     * HTTP POST /v1/account/notificationsettings/
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateNotificationSettings
     */
    updateNotificationSettings(data: NotificationSettingsData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/notificationsettings/`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/account/reset-password
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.resetPassword
     */
    resetPassword(data: ResetPasswordRequestData, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/reset-password`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/account/user
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getCurrentUser
     */
    getCurrentUser(options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/user`, options: options });
    }

    /**
     * HTTP PUT /v1/account/user
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateUser
     */
    updateUser(data: UserData, options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/user`, data: data, options: options });
    }

    /**
     * HTTP PUT /v1/account/user/preferredlocale
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateUserPreferredLocale
     */
    updateUserPreferredLocale(locale: Locale, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/user/preferredlocale`, data: locale, options: options });
    }

    /**
     * HTTP PUT /v1/account/user/usesimplesearch
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateUserUseSimpleSearch
     */
    updateUserUseSimpleSearch(useSimpleSearch: boolean, options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/user/usesimplesearch`, data: useSimpleSearch, options: options });
    }

    /**
     * HTTP DELETE /v1/account/user/{userId}
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.deleteUser
     */
    deleteUser(userId: UUID, options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/account/user/${userId}`, options: options });
    }

    /**
     * HTTP PUT /v1/account/user/{userId}/role/
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.setUserRoles
     */
    setUserRoles(userId: UUID, userRoles: UserRole[], options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/user/${userId}/role/`, data: userRoles, options: options });
    }

    /**
     * HTTP GET /v1/account/users
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getUsers
     */
    getUsers(queryParams?: { excludedUserId?: UUID; }, options?: RequestOptions): RestResponse<Payload<UserData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/account/users`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/account/users/settings/email
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateEmailSettings
     */
    updateEmailSettings(datas: UserData[], options?: RequestOptions): RestResponse<Payload<UserData[]>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/account/users/settings/email`, data: datas, options: options });
    }

    /**
     * HTTP POST /v1/account/validate-token
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.validateToken
     */
    validateToken(data: PasswordTokenData, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/account/validate-token`, data: data, options: options });
    }

    /**
     * HTTP GET /{userId}/notification
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.getNotificationDetails
     */
    getNotificationDetails(userId: string, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`${userId}/notification`, options: options });
    }

    /**
     * HTTP POST /{userId}/notification
     * Java method: com.kps.hhla.backend.rest.endpoints.AccountEndpoint.updateNotificationDetails
     */
    updateNotificationDetails(userId: string, data: NotificationData, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`${userId}/notification`, data: data, options: options });
    }
}

export class AdministrationApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/administration/companyStatistics
     * Java method: com.kps.hhla.backend.rest.endpoints.api.AdministrationApiEndpoint.executeCompanyStatisticsCronjob
     */
    executeCompanyStatisticsCronjob(queryParams?: { date?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/administration/companyStatistics`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/administration/disable
     * Java method: com.kps.hhla.backend.rest.endpoints.api.AdministrationApiEndpoint.disable
     */
    disable(queryParams?: { companyId?: UUID; }, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/administration/disable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/administration/enable
     * Java method: com.kps.hhla.backend.rest.endpoints.api.AdministrationApiEndpoint.enableCompany
     */
    enableCompany(queryParams?: { companyId?: UUID; }, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/administration/enable`, queryParams: queryParams, options: options });
    }
}

export class AdministrationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/administration/company/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.getCompanies
     */
    getCompanies(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<CompanyData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/administration/company/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP LOCK /v1/administration/company/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.disableCompany
     */
    disableCompany(id: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "LOCK", url: uriEncoding`v1/administration/company/${id}`, options: options });
    }

    /**
     * HTTP UNLOCK /v1/administration/company/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.enableCompany
     */
    enableCompany(id: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "UNLOCK", url: uriEncoding`v1/administration/company/${id}`, options: options });
    }

    /**
     * HTTP PUT /v1/administration/company/{id}/closingthreshold/{threshold:.*}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.changeClosingThreshold
     */
    changeClosingThreshold(id: UUID, threshold: number, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/administration/company/${id}/closingthreshold/${threshold}`, options: options });
    }

    /**
     * HTTP POST /v1/administration/company/{id}/premiumRating
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.changePremiumRating
     */
    changePremiumRating(id: UUID, rating: CompanyRating, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/administration/company/${id}/premiumRating`, data: rating, options: options });
    }

    /**
     * HTTP POST /v1/administration/company/{id}/subscription/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.changeSiteSubscription
     */
    changeSiteSubscription(id: UUID, subscription: BookedSubscriptionData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/administration/company/${id}/subscription/`, data: subscription, options: options });
    }

    /**
     * HTTP GET /v1/administration/company/{id}/subscription/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.listAvailableSubscriptions
     */
    listAvailableSubscriptions(id: UUID, options?: RequestOptions): RestResponse<Payload<SubscriptionData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/administration/company/${id}/subscription/`, options: options });
    }

    /**
     * HTTP POST /v1/administration/company/{id}/type/{type}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.changeCompanyType
     */
    changeCompanyType(id: UUID, type: CompanyType, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/administration/company/${id}/type/${type}`, options: options });
    }

    /**
     * HTTP POST /v1/administration/depot/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.createDepot
     */
    createDepot(depot: ContainerDepotLocationData[], options?: RequestOptions): RestResponse<Payload<ContainerDepotLocationData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/administration/depot/`, data: depot, options: options });
    }

    /**
     * HTTP GET /v1/administration/depot/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.getDepots
     */
    getDepots(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<ContainerDepotLocationData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/administration/depot/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /v1/administration/depot/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.deleteDepot
     */
    deleteDepot(id: UUID, options?: RequestOptions): RestResponse<Payload<ContainerDepotLocationData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/administration/depot/${id}`, options: options });
    }

    /**
     * HTTP PUT /v1/administration/depot/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.updateDepot
     */
    updateDepot(id: UUID, data: ContainerDepotLocationData, options?: RequestOptions): RestResponse<Payload<ContainerDepotLocationData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/administration/depot/${id}`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/administration/organization/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.getOrganizations
     */
    getOrganizations(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<OrganizationData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/administration/organization/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP LOCK /v1/administration/organization/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.disableOrganization
     */
    disableOrganization(id: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "LOCK", url: uriEncoding`v1/administration/organization/${id}`, options: options });
    }

    /**
     * HTTP POST /v1/administration/organization/{id}/subscription/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.changeSubscription
     */
    changeSubscription(id: UUID, subscription: BookedSubscriptionData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/administration/organization/${id}/subscription/`, data: subscription, options: options });
    }

    /**
     * HTTP POST /v1/administration/terminal/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.createTerminal
     */
    createTerminal(data: TerminalLocationData[], options?: RequestOptions): RestResponse<Payload<TerminalLocationData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/administration/terminal/`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/administration/terminal/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.getTerminals
     */
    getTerminals(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<TerminalLocationData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/administration/terminal/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /v1/administration/terminal/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.deleteTerminal
     */
    deleteTerminal(id: UUID, options?: RequestOptions): RestResponse<Payload<TerminalLocationData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/administration/terminal/${id}`, options: options });
    }

    /**
     * HTTP PUT /v1/administration/terminal/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.updateTerminal
     */
    updateTerminal(id: UUID, data: TerminalLocationData, options?: RequestOptions): RestResponse<Payload<TerminalLocationData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/administration/terminal/${id}`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/administration/user/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.getUsers
     */
    getUsers(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<UserData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/administration/user/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/administration/user/
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.updateUser
     */
    updateUser(data: UserData, options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/administration/user/`, data: data, options: options });
    }

    /**
     * HTTP DELETE /v1/administration/user/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.AdministrationEndpoint.deleteUser
     */
    deleteUser(id: UUID, options?: RequestOptions): RestResponse<Payload<UserData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/administration/user/${id}`, options: options });
    }
}

export class AuditEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/audit/snapshots/
     * Java method: com.kps.hhla.backend.rest.endpoints.system.AuditEndpoint.getSnapshotStatistics
     */
    getSnapshotStatistics(options?: RequestOptions): RestResponse<{ [index: string]: number }> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/audit/snapshots/`, options: options });
    }

    /**
     * HTTP GET /v1/audit/snapshots/{type}/
     * Java method: com.kps.hhla.backend.rest.endpoints.system.AuditEndpoint.getPersonSnapshots
     */
    getPersonSnapshots(type: string, queryParams?: { id?: UUID; }, options?: RequestOptions): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/audit/snapshots/${type}/`, queryParams: queryParams, options: options });
    }
}

export class AuthenticationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/authentication
     * Java method: com.kps.hhla.backend.rest.endpoints.AuthenticationEndpoint.authenticate
     */
    authenticate(authData: AuthenticationData, options?: RequestOptions): RestResponse<Payload<AuthenticationResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/authentication`, data: authData, options: options });
    }

    /**
     * HTTP GET /v1/authentication
     * Java method: com.kps.hhla.backend.rest.endpoints.AuthenticationEndpoint.getUserInfo
     */
    getUserInfo(options?: RequestOptions): RestResponse<Payload<AuthenticationResponseData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/authentication`, options: options });
    }

    /**
     * HTTP POST /v1/authentication/refresh
     * Java method: com.kps.hhla.backend.rest.endpoints.AuthenticationEndpoint.refresh
     */
    refresh(authData: AuthenticationData, options?: RequestOptions): RestResponse<Payload<AuthenticationResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/authentication/refresh`, data: authData, options: options });
    }
}

export class BaseDataEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/data/countries
     * Java method: com.kps.hhla.backend.rest.endpoints.BaseDataEndpoint.listCountries
     */
    listCountries(options?: RequestOptions): RestResponse<Payload<CountryData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/countries`, options: options });
    }

    /**
     * HTTP GET /v1/data/equipmenttypes
     * Java method: com.kps.hhla.backend.rest.endpoints.BaseDataEndpoint.listEquipmentTypes
     */
    listEquipmentTypes(queryParams?: { sortField?: string; page?: number; pageSize?: number; }, options?: RequestOptions): RestResponse<Payload<PagedResult<EquipmentTypeData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/equipmenttypes`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/data/hazardclassifications
     * Java method: com.kps.hhla.backend.rest.endpoints.BaseDataEndpoint.listHazardClassifications
     */
    listHazardClassifications(queryParams?: { sortField?: string; page?: number; pageSize?: number; }, options?: RequestOptions): RestResponse<Payload<PagedResult<HazardClassificationData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/hazardclassifications`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/data/unnumbers
     * Java method: com.kps.hhla.backend.rest.endpoints.BaseDataEndpoint.getUnnumbers
     */
    getUnnumbers(queryParams?: { hazardClassificationCodes?: string; filter?: string; }, options?: RequestOptions): RestResponse<Payload<UnNumbersResultData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/unnumbers`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/data/weightclasses
     * Java method: com.kps.hhla.backend.rest.endpoints.BaseDataEndpoint.listWeightClasses
     */
    listWeightClasses(queryParams?: { sortField?: string; includeCustom?: boolean; page?: number; pageSize?: number; }, options?: RequestOptions): RestResponse<Payload<PagedResult<WeightClassData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/weightclasses`, queryParams: queryParams, options: options });
    }
}

export class BillingEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/billing
     * Java method: com.kps.hhla.backend.rest.endpoints.billing.BillingEndpoint.getBillingData
     */
    getBillingData(queryParams?: { year?: number; }, options?: RequestOptions): RestResponse<Payload<BillingData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/billing`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/billing/pdf
     * Java method: com.kps.hhla.backend.rest.endpoints.billing.BillingEndpoint.downloadPdf
     */
    downloadPdf(queryParams?: { invoiceId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/billing/pdf`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/billing/pdfs
     * Java method: com.kps.hhla.backend.rest.endpoints.billing.BillingEndpoint.downloadPdfs
     */
    downloadPdfs(queryParams?: { invoiceId?: UUID[]; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/billing/pdfs`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/billing/{year}/{month}
     * Java method: com.kps.hhla.backend.rest.endpoints.billing.BillingEndpoint.createInvoices
     */
    createInvoices(year: number, month: number, queryParams?: { recreate?: boolean; companyUids?: string[]; }, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/billing/${year}/${month}`, queryParams: queryParams, options: options });
    }
}

export class BookingTemplateEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/bookingtemplate
     * Java method: com.kps.hhla.backend.rest.endpoints.BookingTemplateEndpoint.create
     */
    create(data: CreateBookingTemplateRequest, options?: RequestOptions): RestResponse<Payload<BookingTemplateData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/bookingtemplate`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/bookingtemplate/favorites/
     * Java method: com.kps.hhla.backend.rest.endpoints.BookingTemplateEndpoint.listFavorites
     */
    listFavorites(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<BookingFavoriteData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/bookingtemplate/favorites/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP HEAD /v1/bookingtemplate/favorites/{name}
     * Java method: com.kps.hhla.backend.rest.endpoints.BookingTemplateEndpoint.isNameAvailable
     */
    isNameAvailable(name: string, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "HEAD", url: uriEncoding`v1/bookingtemplate/favorites/${name}`, options: options });
    }

    /**
     * HTTP DELETE /v1/bookingtemplate/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.BookingTemplateEndpoint.deleteTemplate
     */
    deleteTemplate(id: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/bookingtemplate/${id}`, options: options });
    }

    /**
     * HTTP GET /v1/bookingtemplate/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.BookingTemplateEndpoint.get
     */
    get(id: UUID, options?: RequestOptions): RestResponse<Payload<BookingTemplateData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/bookingtemplate/${id}`, options: options });
    }
}

export class CacheEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/cache/
     * Java method: com.kps.hhla.backend.rest.endpoints.system.CacheEndpoint.getCacheEntries
     */
    getCacheEntries(options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/cache/`, options: options });
    }

    /**
     * HTTP POST /v1/cache/prefill/all
     * Java method: com.kps.hhla.backend.rest.endpoints.system.CacheEndpoint.prefillCaches
     */
    prefillCaches(options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/cache/prefill/all`, options: options });
    }

    /**
     * HTTP DELETE /v1/cache/{cacheClassName}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.CacheEndpoint.clearCache
     */
    clearCache(cacheClassName: string, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/cache/${cacheClassName}`, options: options });
    }
}

export class CarrierOfferEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/carrier/offers/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.create
     */
    create(offer: CreateOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/carrier/offers/`, data: offer, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/offers/cancel
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.cancelOffer
     */
    cancelOffer(offer: OfferData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/offers/cancel`, data: offer, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/cancel/reasons/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getCancellationReasons
     */
    getCancellationReasons(id: UUID, options?: RequestOptions): RestResponse<Payload<OfferCancellationReason[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/cancel/reasons/${id}`, options: options });
    }

    /**
     * HTTP POST /v1/carrier/offers/export
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.downloadBookings
     */
    downloadBookings(requestData: GetBookingExportRequestData, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/carrier/offers/export`, data: requestData, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/export
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getBookingExport
     */
    getBookingExport(queryParams?: { pageSize?: number; page?: number; searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; legType?: LegType[]; exportStatuses?: ExportStatus[]; participationType?: ParticipationType; }, options?: RequestOptions): RestResponse<Payload<BookingExportResultData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/export`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/offers/incoming/{id}/legs/accept
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.acceptLegs
     */
    acceptLegs(id: UUID, request: UpdateOfferLegRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/offers/incoming/${id}/legs/accept`, data: request, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/offers/incoming/{id}/legs/decline
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.declineLegs
     */
    declineLegs(id: UUID, request: UpdateOfferLegRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/offers/incoming/${id}/legs/decline`, data: request, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/last
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getLastOffers
     */
    getLastOffers(options?: RequestOptions): RestResponse<Payload<OfferData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/last`, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/last/{num}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getLastNumOffers
     */
    getLastNumOffers(num: number, options?: RequestOptions): RestResponse<Payload<OfferData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/last/${num}`, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/next
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getNextOffers
     */
    getNextOffers(options?: RequestOptions): RestResponse<Payload<OfferData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/next`, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/next/{num}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getNextNumOffers
     */
    getNextNumOffers(num: number, options?: RequestOptions): RestResponse<Payload<OfferData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/next/${num}`, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/number
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getNumberOfOffers
     */
    getNumberOfOffers(queryParams?: { exported?: boolean; hoursSinceCreation?: number; completed?: boolean; hoursUntilCompletion?: number; searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<{ [index: string]: number }>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/number`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/overview
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getOfferOverview
     */
    getOfferOverview(queryParams?: { relationId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferStatusOverview>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/overview`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/carrier/offers/search
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.search
     */
    search(searchRequest: OfferSearchRequestData, options?: RequestOptions): RestResponse<Payload<PagedResult<OfferData>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/carrier/offers/search`, data: searchRequest, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/search-filter
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getSearchFilter
     */
    getSearchFilter(queryParams?: { searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<OfferSearchFilterOptionData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/search-filter`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/offers/skip
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.skipOffer
     */
    skipOffer(request: SkipOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/offers/skip`, data: request, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getOffer
     */
    getOffer(id: UUID, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/${id}`, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/offers/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.updateOffer
     */
    updateOffer(id: UUID, offer: UpdateOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/offers/${id}`, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/carrier/offers/{id}/comment
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.addComment
     */
    addComment(id: UUID, data: OfferData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/carrier/offers/${id}/comment`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/carrier/offers/{id}/containers/{containerId}/loadingUnits/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.addLoadingUnit
     */
    addLoadingUnit(id: UUID, containerId: UUID, loadingUnit: LoadingUnitData, options?: RequestOptions): RestResponse<Payload<LoadingUnitData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/carrier/offers/${id}/containers/${containerId}/loadingUnits/`, data: loadingUnit, options: options });
    }

    /**
     * HTTP DELETE /v1/carrier/offers/{id}/containers/{containerId}/loadingUnits/{loadingUnitId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.deleteLoadingUnit
     */
    deleteLoadingUnit(id: UUID, containerId: UUID, loadingUnitId: UUID, options?: RequestOptions): RestResponse<Payload<LoadingUnitData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/carrier/offers/${id}/containers/${containerId}/loadingUnits/${loadingUnitId}`, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/{id}/containers/{containerId}/loadingUnits/{loadingUnitId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getLoadingUnit
     */
    getLoadingUnit(id: UUID, containerId: UUID, loadingUnitId: UUID, options?: RequestOptions): RestResponse<Payload<LoadingUnitData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/${id}/containers/${containerId}/loadingUnits/${loadingUnitId}`, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/offers/{id}/containers/{containerId}/loadingUnits/{loadingUnitId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.updateLoadingUnit
     */
    updateLoadingUnit(id: UUID, containerId: UUID, loadingUnitId: UUID, loadingUnit: LoadingUnitData, options?: RequestOptions): RestResponse<Payload<LoadingUnitData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/offers/${id}/containers/${containerId}/loadingUnits/${loadingUnitId}`, data: loadingUnit, options: options });
    }

    /**
     * HTTP GET /v1/carrier/offers/{offerId}/general-terms
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpoint.getGeneralTerms
     */
    getGeneralTerms(offerId: UUID, queryParams?: { legId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobMetaData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/offers/${offerId}/general-terms`, queryParams: queryParams, options: options });
    }
}

export class CarrierOfferEndpointTemporaryClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /v2/carrier/offers/cancel
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.CarrierOfferEndpointTemporary.cancelOffer
     */
    cancelOffer(request: CancelOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v2/carrier/offers/cancel`, data: request, options: options });
    }
}

export class CarrierPricelistEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/carrier/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.carrier.CarrierPricelistEndpoint.getPricelistNames
     */
    getPricelistNames(queryParams?: { operatorId?: UUID; }, options?: RequestOptions): RestResponse<Payload<PagedResult<SchedulePricelistData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/pricelist/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/carrier/pricelist/export/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.carrier.CarrierPricelistEndpoint.exportPricelist
     */
    exportPricelist(pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/pricelist/export/${pricelistId}`, options: options });
    }

    /**
     * HTTP GET /v1/carrier/pricelist/operator/
     * Java method: com.kps.hhla.backend.rest.endpoints.carrier.CarrierPricelistEndpoint.listOperators
     */
    listOperators(options?: RequestOptions): RestResponse<Payload<CompanyData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/pricelist/operator/`, options: options });
    }

    /**
     * HTTP PUT /v1/carrier/pricelist/operator/{operatorId}
     * Java method: com.kps.hhla.backend.rest.endpoints.carrier.CarrierPricelistEndpoint.requestPricelist
     */
    requestPricelist(operatorId: UUID, queryParams?: { pricelistId?: UUID; }, options?: RequestOptions): RestResponse<Payload<number>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/carrier/pricelist/operator/${operatorId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/carrier/pricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.carrier.CarrierPricelistEndpoint.getPricelist
     */
    getPricelist(pricelistId: UUID, queryParams?: { startTerminal?: string; endTerminal?: string; equipmentType?: UUID; page?: number; pageSize?: number; applyToReturnTour?: boolean; }, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/carrier/pricelist/${pricelistId}`, queryParams: queryParams, options: options });
    }
}

export class CompanyPoiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/companypoi
     * Java method: com.kps.hhla.backend.rest.endpoints.CompanyPoiEndpoint.createCompanyPoi
     */
    createCompanyPoi(datas: AddressLocationData[], options?: RequestOptions): RestResponse<Payload<AddressLocationData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/companypoi`, data: datas, options: options });
    }

    /**
     * HTTP GET /v1/companypoi/search/
     * Java method: com.kps.hhla.backend.rest.endpoints.CompanyPoiEndpoint.searchCompanyPois
     */
    searchCompanyPois(queryParams?: { searchTerm?: string; addressId?: UUID; latitude?: number; longitude?: number; distance?: number; }, options?: RequestOptions): RestResponse<Payload<AddressLocationData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/companypoi/search/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/companypoi/{id}/
     * Java method: com.kps.hhla.backend.rest.endpoints.CompanyPoiEndpoint.findCompanyPoiById
     */
    findCompanyPoiById(id: UUID, options?: RequestOptions): RestResponse<Payload<AddressLocationData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/companypoi/${id}/`, options: options });
    }
}

export class ContainerDepotEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/depot/search
     * Java method: com.kps.hhla.backend.rest.endpoints.ContainerDepotEndpoint.search
     */
    search(queryParams?: { latitude?: number; longitude?: number; equipmentTypeId?: UUID; equipmentOwnerId?: UUID; terminalId?: UUID; providerId?: UUID; relationPriceID?: UUID; date?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<ContainerDepotResultData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/depot/search`, queryParams: queryParams, options: options });
    }
}

export class CronJobEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/cronjob/{cronJob}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.CronJobEndpoint.runCronJob
     */
    runCronJob(cronJob: string, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/cronjob/${cronJob}`, options: options });
    }
}

export class EquipmentOwnerEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/equipmentowner/
     * Java method: com.kps.hhla.backend.rest.endpoints.EquipmentOwnerEndpoint.createEquipmentOwners
     */
    createEquipmentOwners(equipmentOwners: EquipmentOwnerData[], options?: RequestOptions): RestResponse<Payload<EquipmentOwnerData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/equipmentowner/`, data: equipmentOwners, options: options });
    }

    /**
     * HTTP GET /v1/equipmentowner/
     * Java method: com.kps.hhla.backend.rest.endpoints.EquipmentOwnerEndpoint.listEquipmentOwners
     */
    listEquipmentOwners(options?: RequestOptions): RestResponse<Payload<EquipmentOwnerData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/equipmentowner/`, options: options });
    }

    /**
     * HTTP DELETE /v1/equipmentowner/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.EquipmentOwnerEndpoint.deleteEquipmentOwner
     */
    deleteEquipmentOwner(id: UUID, options?: RequestOptions): RestResponse<Payload<EquipmentOwnerData[]>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/equipmentowner/${id}`, options: options });
    }
}

export class EquipmentTypeApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/equipmenttype/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.EquipmentTypeApiEndpoint.listEquipmentTypes
     */
    listEquipmentTypes(options?: RequestOptions): RestResponse<Payload<EquipmentTypeData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/equipmenttype/`, options: options });
    }
}

export class ExportEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/export/account/{companyId}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.ExportEndpoint.exportData
     */
    exportData(companyId: string, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/export/account/${companyId}`, options: options });
    }
}

export class FeatureFlagEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/features/
     * Java method: com.kps.hhla.backend.rest.endpoints.FeatureFlagEndpoint.listFlags
     */
    listFlags(options?: RequestOptions): RestResponse<Payload<string[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/features/`, options: options });
    }

    /**
     * HTTP GET /v1/features/flag/
     * Java method: com.kps.hhla.backend.rest.endpoints.FeatureFlagEndpoint.getAllFlagStates
     */
    getAllFlagStates(options?: RequestOptions): RestResponse<Payload<{ [index: string]: boolean }>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/features/flag/`, options: options });
    }

    /**
     * HTTP GET /v1/features/flag/{flag}
     * Java method: com.kps.hhla.backend.rest.endpoints.FeatureFlagEndpoint.getFlagStatus
     */
    getFlagStatus(flag: string, options?: RequestOptions): RestResponse<Payload<{ [index: string]: boolean }>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/features/flag/${flag}`, options: options });
    }

    /**
     * HTTP PUT /v1/features/flag/{flag}/{status}
     * Java method: com.kps.hhla.backend.rest.endpoints.FeatureFlagEndpoint.switchFlag
     */
    switchFlag(flag: string, status: string, options?: RequestOptions): RestResponse<Payload<{ [index: string]: boolean }>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/features/flag/${flag}/${status}`, options: options });
    }
}

export class GeoLocationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/geolocation/address
     * Java method: com.kps.hhla.backend.rest.endpoints.GeoLocationEndpoint.getAddress
     */
    getAddress(queryParams?: { latitude?: number; longitude?: number; }, options?: RequestOptions): RestResponse<Payload<AddressLocationData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/geolocation/address`, queryParams: queryParams, options: options });
    }

    /**
     * @deprecated
     * HTTP GET /v1/geolocation/address/correct
     * Java method: com.kps.hhla.backend.rest.endpoints.GeoLocationEndpoint.correctAddress
     */
    correctAddress(queryParams?: { address?: string; }, options?: RequestOptions): RestResponse<Payload<AddressLocationData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/geolocation/address/correct`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/geolocation/coordinates
     * Java method: com.kps.hhla.backend.rest.endpoints.GeoLocationEndpoint.getCoordinates
     */
    getCoordinates(queryParams?: { address?: string; }, options?: RequestOptions): RestResponse<Payload<GeoLocationData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/geolocation/coordinates`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/geolocation/distance
     * Java method: com.kps.hhla.backend.rest.endpoints.GeoLocationEndpoint.getDistance
     */
    getDistance(queryParams?: { address1?: string; address2?: string; type?: CalculationType; }, options?: RequestOptions): RestResponse<Payload<{ [index: string]: DistanceData }>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/geolocation/distance`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/geolocation/duration
     * Java method: com.kps.hhla.backend.rest.endpoints.GeoLocationEndpoint.calculateDuration
     */
    calculateDuration(request: RequestRouteData, options?: RequestOptions): RestResponse<Payload<DurationData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/geolocation/duration`, data: request, options: options });
    }
}

export class HarmonizedCommodityCodeEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/data/nhm/
     * Java method: com.kps.hhla.backend.rest.endpoints.HarmonizedCommodityCodeEndpoint.list
     */
    list$GET$v1_data_nhm_(queryParams?: { locale?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<HarmonizedCommodityCodeData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/nhm/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/data/nhm/import/
     * Java method: com.kps.hhla.backend.rest.endpoints.HarmonizedCommodityCodeEndpoint.importData
     */
    importData(dataStream: any, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<HarmonizedCommodityCodeImportData, any>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/data/nhm/import/`, data: dataStream, options: options });
    }

    /**
     * HTTP GET /v1/data/nhm/{codePrefix}
     * Java method: com.kps.hhla.backend.rest.endpoints.HarmonizedCommodityCodeEndpoint.list
     */
    list$GET$v1_data_nhm_codePrefix(codePrefix: string, queryParams?: { locale?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<HarmonizedCommodityCodeData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/data/nhm/${codePrefix}`, queryParams: queryParams, options: options });
    }
}

export class HazardClassificationApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/hazardclassification/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.HazardClassificationApiEndpoint.listHazardClassifications
     */
    listHazardClassifications(options?: RequestOptions): RestResponse<Payload<HazardClassificationData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/hazardclassification/`, options: options });
    }
}

export class ImportEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/import/generate/depot-prices
     * Java method: com.kps.hhla.backend.rest.endpoints.system.ImportEndpoint.generateDepotPrices
     */
    generateDepotPrices(options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/import/generate/depot-prices`, options: options });
    }

    /**
     * HTTP POST /v1/import/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.system.ImportEndpoint.importPricelist
     */
    importPricelist(request: ImportSchedulePricelistRequestData, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/import/pricelist/`, data: request, options: options });
    }

    /**
     * HTTP POST /v1/import/{entityType}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.ImportEndpoint.importEntities
     */
    importEntities(entityType: string, inputStream: any, queryParams?: { dryRun?: boolean; overwrite?: boolean; }, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/import/${entityType}`, queryParams: queryParams, data: inputStream, options: options });
    }
}

export class InitializationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/initialization/import/{dataType}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.InitializationEndpoint.importData
     */
    importData(dataType: ImportDataType, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/initialization/import/${dataType}`, options: options });
    }

    /**
     * HTTP GET /v1/initialization/status/{initProcess}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.InitializationEndpoint.status
     */
    status(initProcess: string, options?: RequestOptions): RestResponse<Payload<boolean>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/initialization/status/${initProcess}`, options: options });
    }

    /**
     * HTTP GET /v1/initialization/typesystem/{typeSystemAction}
     * Java method: com.kps.hhla.backend.rest.endpoints.system.InitializationEndpoint.initializeTypesystem
     */
    initializeTypesystem(typeSystemAction: TypeSystemAction, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/initialization/typesystem/${typeSystemAction}`, options: options });
    }
}

export class JpaQueryEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/persistence/query
     * Java method: com.kps.hhla.backend.rest.endpoints.system.JpaQueryEndpoint.query
     */
    query(jpaQuery: string, queryParams?: { format?: Format; nativeQuery?: boolean; caseConverter?: CaseFormat; }, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/persistence/query`, queryParams: queryParams, data: jpaQuery, options: options });
    }
}

export class LegPricelistApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/legpricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.get
     */
    get(queryParams?: { terminalId?: UUID; }, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/legpricelist/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v1/legpricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.upload
     */
    upload(data: UpdateLegPricelistRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/legpricelist/`, data: data, options: options });
    }

    /**
     * HTTP POST /api/v1/legpricelist/{pricelistId}/depotPrice
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.createDepotPrice
     */
    createDepotPrice(pricelistId: UUID, request: CreateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/legpricelist/${pricelistId}/depotPrice`, data: request, options: options });
    }

    /**
     * HTTP DELETE /api/v1/legpricelist/{pricelistId}/depotPrice/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.deleteDepotPrice
     */
    deleteDepotPrice(pricelistId: UUID, priceId: UUID, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/legpricelist/${pricelistId}/depotPrice/${priceId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/legpricelist/{pricelistId}/depotPrice/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.updateDepotPrice
     */
    updateDepotPrice(pricelistId: UUID, priceId: UUID, request: UpdateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/legpricelist/${pricelistId}/depotPrice/${priceId}`, data: request, options: options });
    }

    /**
     * HTTP POST /api/v1/legpricelist/{pricelistId}/legPrice
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.createLegPrice
     */
    createLegPrice(pricelistId: UUID, request: CreateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/legpricelist/${pricelistId}/legPrice`, data: request, options: options });
    }

    /**
     * HTTP DELETE /api/v1/legpricelist/{pricelistId}/legPrice/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.deleteLegPrice
     */
    deleteLegPrice(pricelistId: UUID, priceId: UUID, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/legpricelist/${pricelistId}/legPrice/${priceId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/legpricelist/{pricelistId}/legPrice/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.updateLegPrice
     */
    updateLegPrice(pricelistId: UUID, priceId: UUID, request: UpdateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/legpricelist/${pricelistId}/legPrice/${priceId}`, data: request, options: options });
    }

    /**
     * HTTP POST /api/v1/legpricelist/{pricelistId}/price
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.createPrice
     */
    createPrice(pricelistId: UUID, request: CreateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/legpricelist/${pricelistId}/price`, data: request, options: options });
    }

    /**
     * HTTP DELETE /api/v1/legpricelist/{pricelistId}/price/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.deletePrice
     */
    deletePrice(pricelistId: UUID, priceId: UUID, queryParams?: { type?: LegPriceType; }, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/legpricelist/${pricelistId}/price/${priceId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /api/v1/legpricelist/{pricelistId}/price/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.updatePrice
     */
    updatePrice(pricelistId: UUID, priceId: UUID, request: UpdateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/legpricelist/${pricelistId}/price/${priceId}`, data: request, options: options });
    }

    /**
     * HTTP POST /api/v1/legpricelist/{pricelistId}/surcharge
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.createSurcharge
     */
    createSurcharge(pricelistId: UUID, request: CreateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/legpricelist/${pricelistId}/surcharge`, data: request, options: options });
    }

    /**
     * HTTP DELETE /api/v1/legpricelist/{pricelistId}/surcharge/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.deleteSurcharge
     */
    deleteSurcharge(pricelistId: UUID, priceId: UUID, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/legpricelist/${pricelistId}/surcharge/${priceId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/legpricelist/{pricelistId}/surcharge/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LegPricelistApiEndpoint.updateSurcharge
     */
    updateSurcharge(pricelistId: UUID, priceId: UUID, request: UpdateLegPriceRequestData, options?: RequestOptions): RestResponse<Payload<GetLegPricelistResponseData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/legpricelist/${pricelistId}/surcharge/${priceId}`, data: request, options: options });
    }
}

export class LegPricelistEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/legpricelist
     * Java method: com.kps.hhla.backend.rest.endpoints.LegPricelistEndpoint.getPricelists
     */
    getPricelists(queryParams?: { terminalId?: UUID; }, options?: RequestOptions): RestResponse<Payload<LegPricelistData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/legpricelist`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/legpricelist/export/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.LegPricelistEndpoint.download
     */
    download(pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/legpricelist/export/${pricelistId}`, options: options });
    }

    /**
     * HTTP GET /v1/legpricelist/template
     * Java method: com.kps.hhla.backend.rest.endpoints.LegPricelistEndpoint.getTemplate
     */
    getTemplate(queryParams?: { terminalId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/legpricelist/template`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/legpricelist/terminal/{terminalId}
     * Java method: com.kps.hhla.backend.rest.endpoints.LegPricelistEndpoint.upload
     */
    upload(terminalId: UUID, body: BlobData, options?: RequestOptions): RestResponse<Payload<LegPricelistData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/legpricelist/terminal/${terminalId}`, data: body, options: options });
    }

    /**
     * HTTP DELETE /v1/legpricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.LegPricelistEndpoint.delete
     */
    delete(pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<any>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/legpricelist/${pricelistId}`, options: options });
    }

    /**
     * HTTP GET /v1/legpricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.LegPricelistEndpoint.get
     */
    get(pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<LegPricelistData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/legpricelist/${pricelistId}`, options: options });
    }
}

export class LocalStorageEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /storage/d/{folder:.+}/f/{fileName}
     * Java method: com.kps.hhla.backend.rest.endpoints.LocalStorageEndpoint.download
     */
    download(folder: string, fileName: string, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`storage/d/${folder}/f/${fileName}`, options: options });
    }
}

export class LocationApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/location/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.LocationApiEndpoint.listLocations
     */
    listLocations(queryParams?: { searchTerm?: string; }, options?: RequestOptions): RestResponse<Payload<LocationApiResponseData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/location/`, queryParams: queryParams, options: options });
    }
}

export class LocationSearchEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/locations
     * Java method: com.kps.hhla.backend.rest.endpoints.search.LocationSearchEndpoint.search
     */
    search(queryParams?: { searchTerm?: string; resultFields?: string; isSeaTerminal?: boolean; includeCompanyPOIs?: boolean; isRailTerminal?: boolean; sortField?: string; page?: number; pageSize?: number; }, options?: RequestOptions): RestResponse<Payload<LocationSearchResult>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/locations`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/locations/searchNear
     * Java method: com.kps.hhla.backend.rest.endpoints.search.LocationSearchEndpoint.searchNear
     */
    searchNear(queryParams?: { latitude?: number; longitude?: number; distance?: number; searchTerm?: string; isSeaTerminal?: boolean; isRailTerminal?: boolean; sortField?: string; page?: number; pageSize?: number; }, options?: RequestOptions): RestResponse<Payload<LocationSearchResult>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/locations/searchNear`, queryParams: queryParams, options: options });
    }

    /**
     * @deprecated
     * HTTP GET /v1/locations/sortOptions
     * Java method: com.kps.hhla.backend.rest.endpoints.search.LocationSearchEndpoint.getSortOptions
     */
    getSortOptions(options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/locations/sortOptions`, options: options });
    }
}

export class OfferApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/offer/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferApiEndpoint.createOffer
     */
    createOffer(request: CreateOfferApiRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offer/`, data: request, options: options });
    }

    /**
     * HTTP POST /api/v1/offer/cancel
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferApiEndpoint.cancelOffer
     */
    cancelOffer(request: CancelOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offer/cancel`, data: request, options: options });
    }

    /**
     * HTTP POST /api/v1/offer/decline
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferApiEndpoint.declineOffer
     */
    declineOffer(request: CancelOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offer/decline`, data: request, options: options });
    }

    /**
     * HTTP POST /api/v1/offer/exports/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferApiEndpoint.getBookingExports
     */
    getBookingExports(request: BasicGetOffersRequestData, options?: RequestOptions): RestResponse<Payload<PagedResult<OfferData>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offer/exports/`, data: request, options: options });
    }

    /**
     * HTTP PUT /api/v1/offer/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferApiEndpoint.updateOffer
     */
    updateOffer(id: UUID, offer: UpdateOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/offer/${id}`, data: offer, options: options });
    }

    /**
     * HTTP POST /api/v1/offer/{id}/accept
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferApiEndpoint.acceptOffer
     */
    acceptOffer(id: UUID, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offer/${id}/accept`, options: options });
    }
}

export class OfferChangeRequestEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/offer-changerequests
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.create
     */
    create(request: OfferChangeRequestData, options?: RequestOptions): RestResponse<Payload<OfferChangeRequestData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/offer-changerequests`, data: request, options: options });
    }

    /**
     * HTTP GET /v1/offer-changerequests
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.getPendingRequests
     */
    getPendingRequests(queryParams?: { offerId?: UUID[]; }, options?: RequestOptions): RestResponse<Payload<OfferChangeRequestData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/offer-changerequests`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/offer-changerequests/history/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.getHistory
     */
    getHistory(queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferChangeRequestData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/offer-changerequests/history/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/offer-changerequests/relations/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.getPossibleRelationAlternatives
     */
    getPossibleRelationAlternatives(queryParams?: { offerId?: UUID; fromDate?: DateAsString; toDate?: DateAsString; page?: number; pageSize?: number; onlyIncludeRelationsWithFreeCapacities?: boolean; }, options?: RequestOptions): RestResponse<Payload<PagedResult<RelationData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/offer-changerequests/relations/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/offer-changerequests/{changeRequestId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.accept
     */
    accept(changeRequestId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/offer-changerequests/${changeRequestId}`, options: options });
    }

    /**
     * HTTP PATCH /v1/offer-changerequests/{changeRequestId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.cancel
     */
    cancel(changeRequestId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PATCH", url: uriEncoding`v1/offer-changerequests/${changeRequestId}`, options: options });
    }

    /**
     * HTTP DELETE /v1/offer-changerequests/{changeRequestId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferChangeRequestEndpoint.decline
     */
    decline(changeRequestId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/offer-changerequests/${changeRequestId}`, options: options });
    }
}

export class OfferDocumentsApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/offerdocuments/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.getOfferDocuments
     */
    getOfferDocuments(queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/offerdocuments/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v1/offerdocuments/customs/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.uploadCustomsDocument
     */
    uploadCustomsDocument(documents: BlobData[], queryParams?: { containerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offerdocuments/customs/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP POST /api/v1/offerdocuments/dangerousgoods/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.uploadDangerousGoodsDocument
     */
    uploadDangerousGoodsDocument(documents: BlobData[], queryParams?: { loadingUnitId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offerdocuments/dangerousgoods/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP POST /api/v1/offerdocuments/goods/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.uploadGoodsDocument
     */
    uploadGoodsDocument(documents: BlobData[], queryParams?: { loadingUnitId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offerdocuments/goods/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP POST /api/v1/offerdocuments/offer/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.uploadOfferDocument
     */
    uploadOfferDocument(documents: BlobData[], queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/offerdocuments/offer/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP DELETE /api/v1/offerdocuments/{documentId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.deleteDocument
     */
    deleteDocument(documentId: UUID, queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/offerdocuments/${documentId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/offerdocuments/{documentId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.OfferDocumentsApiEndpoint.getDocument
     */
    getDocument(documentId: UUID, queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/offerdocuments/${documentId}`, queryParams: queryParams, options: options });
    }
}

export class OfferDocumentsEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/offerdocuments/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.getOfferDocuments
     */
    getOfferDocuments(queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/offerdocuments/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/offerdocuments/customs/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.uploadCustomsDocument
     */
    uploadCustomsDocument(documents: BlobData[], queryParams?: { containerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/offerdocuments/customs/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP POST /v1/offerdocuments/dangerousgoods/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.uploadDangerousGoodsDocument
     */
    uploadDangerousGoodsDocument(documents: BlobData[], queryParams?: { loadingUnitId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/offerdocuments/dangerousgoods/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP POST /v1/offerdocuments/goods/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.uploadGoodsDocument
     */
    uploadGoodsDocument(documents: BlobData[], queryParams?: { loadingUnitId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/offerdocuments/goods/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP POST /v1/offerdocuments/offer/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.uploadOfferDocument
     */
    uploadOfferDocument(documents: BlobData[], queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferDocumentUploadResultData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/offerdocuments/offer/`, queryParams: queryParams, data: documents, options: options });
    }

    /**
     * HTTP PATCH /v1/offerdocuments/{documentId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.changeDocument
     */
    changeDocument(documentId: UUID, document: OfferDocumentData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PATCH", url: uriEncoding`v1/offerdocuments/${documentId}`, data: document, options: options });
    }

    /**
     * HTTP DELETE /v1/offerdocuments/{documentId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.deleteDocument
     */
    deleteDocument(documentId: UUID, queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/offerdocuments/${documentId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/offerdocuments/{documentId}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferDocumentsEndpoint.getDocument
     */
    getDocument(documentId: UUID, queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/offerdocuments/${documentId}`, queryParams: queryParams, options: options });
    }
}

export class OfferHistoryEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/offerhistory/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OfferHistoryEndpoint.getHistory
     */
    getHistory(queryParams?: { offerId?: UUID; }, options?: RequestOptions): RestResponse<Payload<HistoryEntryData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/offerhistory/`, queryParams: queryParams, options: options });
    }
}

export class OperatorIndividualPricelistEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/operator/pricelist/individual/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorIndividualPricelistEndpoint.getIndividualPricelistNames
     */
    getIndividualPricelistNames(queryParams?: { basePricelistId?: UUID; customerOrganizationId?: UUID; }, options?: RequestOptions): RestResponse<Payload<PagedResult<SchedulePricelistData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/pricelist/individual/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/operator/pricelist/individual/customers/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorIndividualPricelistEndpoint.listCustomers
     */
    listCustomers(queryParams?: { searchTerm?: string; }, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/pricelist/individual/customers/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/operator/pricelist/individual/declinerequest
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorIndividualPricelistEndpoint.declineIndividualPriceListRequest
     */
    declineIndividualPriceListRequest(queryParams?: { customerOgranizationId?: UUID; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/pricelist/individual/declinerequest`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/operator/pricelist/individual/{basePricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorIndividualPricelistEndpoint.createIndividualPricelist
     */
    createIndividualPricelist(basePricelistId: UUID, customerOrganizationIds: UUID[], options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/pricelist/individual/${basePricelistId}`, data: customerOrganizationIds, options: options });
    }

    /**
     * HTTP DELETE /v1/operator/pricelist/individual/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorIndividualPricelistEndpoint.deleteIndividualPricelist
     */
    deleteIndividualPricelist(pricelistId: UUID, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/operator/pricelist/individual/${pricelistId}`, options: options });
    }

    /**
     * HTTP GET /v1/operator/pricelist/individual/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorIndividualPricelistEndpoint.getIndividualPricelist
     */
    getIndividualPricelist(pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/pricelist/individual/${pricelistId}`, options: options });
    }
}

export class OperatorOfferEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/operator/offers/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getOffers
     */
    getOffers(queryParams?: { relationId?: UUID; pageSize?: number; page?: number; sortField?: string; exported?: boolean; hoursSinceCreation?: number; completed?: boolean; hoursUntilCompletion?: number; searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; filter?: OfferFilter[]; }, options?: RequestOptions): RestResponse<Payload<PagedResult<OfferData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/operator/offers/accept
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.acceptOffer
     */
    acceptOffer(offer: OfferData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/operator/offers/accept`, data: offer, options: options });
    }

    /**
     * HTTP PUT /v1/operator/offers/decline
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.declineOffer
     */
    declineOffer(offer: OfferData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/operator/offers/decline`, data: offer, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/decline/reasons/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getCancellationReasons
     */
    getCancellationReasons(id: UUID, options?: RequestOptions): RestResponse<Payload<OfferCancellationReason[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/decline/reasons/${id}`, options: options });
    }

    /**
     * HTTP POST /v1/operator/offers/export
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.downloadBookings
     */
    downloadBookings(requestData: GetBookingExportRequestData, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/offers/export`, data: requestData, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/export
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getBookingExport
     */
    getBookingExport(queryParams?: { pageSize?: number; page?: number; searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; legType?: LegType[]; exportStatuses?: ExportStatus[]; }, options?: RequestOptions): RestResponse<Payload<BookingExportResultData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/export`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/number
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getNumberOfOffers
     */
    getNumberOfOffers(queryParams?: { exported?: boolean; hoursSinceCreation?: number; completed?: boolean; hoursUntilCompletion?: number; searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<{ [index: string]: number }>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/number`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/overview
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getOfferOverview
     */
    getOfferOverview(queryParams?: { relationId?: UUID; }, options?: RequestOptions): RestResponse<Payload<OfferStatusOverview>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/overview`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/operator/offers/search
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.search
     */
    search(searchRequest: OfferSearchRequestData, options?: RequestOptions): RestResponse<Payload<PagedResult<OfferData>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/offers/search`, data: searchRequest, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/search-filter
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getSearchFilter
     */
    getSearchFilter(queryParams?: { searchTerm?: string; bookingFrom?: DateAsString; bookingTo?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<OfferSearchFilterOptionData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/search-filter`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getOffer
     */
    getOffer(id: UUID, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/${id}`, options: options });
    }

    /**
     * HTTP PUT /v1/operator/offers/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.updateOffer
     */
    updateOffer(id: UUID, offer: OfferData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/operator/offers/${id}`, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/operator/offers/{id}/comment
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.addComment
     */
    addComment(id: UUID, data: OfferData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/offers/${id}/comment`, data: data, options: options });
    }

    /**
     * HTTP PUT /v1/operator/offers/{id}/legs/accept
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.acceptLegs
     */
    acceptLegs(id: UUID, request: UpdateOfferLegRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/operator/offers/${id}/legs/accept`, data: request, options: options });
    }

    /**
     * HTTP GET /v1/operator/offers/{offerId}/general-terms
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpoint.getGeneralTerms
     */
    getGeneralTerms(offerId: UUID, queryParams?: { legId?: UUID; }, options?: RequestOptions): RestResponse<Payload<BlobMetaData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/offers/${offerId}/general-terms`, queryParams: queryParams, options: options });
    }
}

export class OperatorOfferEndpointTemporaryClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP PUT /v2/operator/offers/decline
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.OperatorOfferEndpointTemporary.declineOffer
     */
    declineOffer(request: CancelOfferRequestData, options?: RequestOptions): RestResponse<Payload<OfferData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v2/operator/offers/decline`, data: request, options: options });
    }
}

export class OperatorPricelistEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/schedule/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorPricelistEndpoint.getPricelistNames
     */
    getPricelistNames(options?: RequestOptions): RestResponse<Payload<PagedResult<SchedulePricelistData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/pricelist/`, options: options });
    }

    /**
     * HTTP PATCH /v1/schedule/pricelist/allInPricelists
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorPricelistEndpoint.updatePricelistProperties
     */
    updatePricelistProperties(queryParams?: { allInPricelists?: boolean; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PATCH", url: uriEncoding`v1/schedule/pricelist/allInPricelists`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/schedule/pricelist/import
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorPricelistEndpoint.importPricelist
     */
    importPricelist(body: BlobData, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/schedule/pricelist/import`, data: body, options: options });
    }
}

export class OperatorRelationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/operator/relations/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.createRelation
     */
    createRelation(data: RelationUpdateRequestData, options?: RequestOptions): RestResponse<Payload<RelationData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/relations/`, data: data, options: options });
    }

    /**
     * HTTP DELETE /v1/operator/relations/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.deleteRelation
     */
    deleteRelation(deleteRequest: DeleteRelationTimetableEntryRequestData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/operator/relations/`, data: deleteRequest, options: options });
    }

    /**
     * HTTP POST /v1/operator/relations/notifications
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.sendNotificationsToOffers
     */
    sendNotificationsToOffers(request: SendRelationNotification, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/relations/notifications`, data: request, options: options });
    }

    /**
     * HTTP POST /v1/operator/relations/search
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.listRelationsBetweenDates
     */
    listRelationsBetweenDates(searchRequest: ListRelationRequestData, options?: RequestOptions): RestResponse<Payload<PagedResult<RelationData>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/relations/search`, data: searchRequest, options: options });
    }

    /**
     * HTTP POST /v1/operator/relations/search2
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.listRelationsBetweenDates2
     */
    listRelationsBetweenDates2(searchRequest: ListRelationRequestData, options?: RequestOptions): RestResponse<Payload<ListRelationResultData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/relations/search2`, data: searchRequest, options: options });
    }

    /**
     * HTTP GET /v1/operator/relations/{relationId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.getRelation
     */
    getRelation(relationId: UUID, queryParams?: { dayOfClosing?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<RelationUpdateRequestData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/relations/${relationId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/operator/relations/{relationId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationEndpoint.updateRelation
     */
    updateRelation(relationId: UUID, data: RelationUpdateRequestData, options?: RequestOptions): RestResponse<Payload<RelationData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/operator/relations/${relationId}`, data: data, options: options });
    }
}

export class OperatorRelationScheduleEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/schedule/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.listSchedules
     */
    listSchedules(options?: RequestOptions): RestResponse<Payload<PagedResult<ScheduleData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/`, options: options });
    }

    /**
     * HTTP GET /v1/schedule/export/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.exportSchedule
     */
    exportSchedule(scheduleId: UUID, queryParams?: { operatorUid?: string; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/export/${scheduleId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/schedule/import
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.importSchedule
     */
    importSchedule(body: BlobData, queryParams?: { format?: ImportFormat; }, options?: RequestOptions): RestResponse<Payload<ScheduleData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/schedule/import`, queryParams: queryParams, data: body, options: options });
    }

    /**
     * HTTP PUT /v1/schedule/import/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.updateSchedule
     */
    updateSchedule(scheduleId: UUID, body: BlobData, queryParams?: { format?: ImportFormat; }, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ScheduleEntryData, { [index: string]: string }>>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/schedule/import/${scheduleId}`, queryParams: queryParams, data: body, options: options });
    }

    /**
     * HTTP GET /v1/schedule/template
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.downloadTemplate
     */
    downloadTemplate(options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/template`, options: options });
    }

    /**
     * HTTP LOCK /v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.archiveSchedule
     */
    archiveSchedule(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "LOCK", url: uriEncoding`v1/schedule/${scheduleId}`, options: options });
    }

    /**
     * HTTP DELETE /v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.deleteSchedule
     */
    deleteSchedule(scheduleId: UUID, deleteRequest: DeleteScheduleRequestData, queryParams?: { operatorUid?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/schedule/${scheduleId}`, queryParams: queryParams, data: deleteRequest, options: options });
    }

    /**
     * HTTP GET /v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.listSchedule
     */
    listSchedule(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<ScheduleData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/${scheduleId}`, options: options });
    }

    /**
     * HTTP COPY /v1/schedule/{scheduleId}/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.createFollowUpSchedule
     */
    createFollowUpSchedule(scheduleId: UUID, queryParams?: { validTo?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<CreateFollowUpScheduleResponseData>> {
        return this.httpClient.request({ method: "COPY", url: uriEncoding`v1/schedule/${scheduleId}/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/schedule/{scheduleId}/validity
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorRelationScheduleEndpoint.updateValidity
     */
    updateValidity(scheduleId: UUID, queryParams?: { validFrom?: DateAsString; validTo?: DateAsString; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/schedule/${scheduleId}/validity`, queryParams: queryParams, options: options });
    }
}

export class OperatorSpecialScheduleEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /v1/specialschedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorSpecialScheduleEndpoint.deleteSchedule
     */
    deleteSchedule(scheduleId: UUID, deleteRequest: DeleteScheduleRequestData, queryParams?: { operatorUid?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/specialschedule/${scheduleId}`, queryParams: queryParams, data: deleteRequest, options: options });
    }

    /**
     * HTTP POST /v1/specialschedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorSpecialScheduleEndpoint.importSpecialSchedule
     */
    importSpecialSchedule(scheduleId: UUID, body: BlobData, queryParams?: { format?: ImportFormat; }, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ScheduleEntryData, { [index: string]: string }>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/specialschedule/${scheduleId}`, queryParams: queryParams, data: body, options: options });
    }

    /**
     * HTTP PUT /v1/specialschedule/{specialScheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorSpecialScheduleEndpoint.updateSpecialSchedule
     */
    updateSpecialSchedule(specialScheduleId: UUID, body: BlobData, queryParams?: { format?: ImportFormat; }, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ScheduleEntryData, { [index: string]: string }>>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/specialschedule/${specialScheduleId}`, queryParams: queryParams, data: body, options: options });
    }
}

export class OperatorTerminalEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/operator/terminals
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.OperatorTerminalEndpoint.getTerminals
     */
    getTerminals(options?: RequestOptions): RestResponse<Payload<TerminalLocationData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/terminals`, options: options });
    }
}

export class OrganizationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/organization/site/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.switchSite
     */
    switchSite(request: ImpersonateCompanyRequestData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/organization/site/`, data: request, options: options });
    }

    /**
     * HTTP POST /v1/organization/site/{siteId}/users/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.moveUsersToCompany
     */
    moveUsersToCompany(siteId: UUID, users: UserData[], options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/organization/site/${siteId}/users/`, data: users, options: options });
    }

    /**
     * HTTP GET /v1/organization/users/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.listUsers
     */
    listUsers(queryParams?: { filter?: string; page?: number; pageSize?: number; sortField?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<UserData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/organization/users/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/organization/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.getOrganization
     */
    getOrganization(id: UUID, options?: RequestOptions): RestResponse<Payload<OrganizationData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/organization/${id}`, options: options });
    }

    /**
     * HTTP POST /v1/organization/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.updateOrganization
     */
    updateOrganization(id: UUID, newData: OrganizationData, options?: RequestOptions): RestResponse<Payload<OrganizationData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/organization/${id}`, data: newData, options: options });
    }

    /**
     * HTTP DELETE /v1/organization/{id}/billingaddress/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.deleteOrganizationBillingAddress
     */
    deleteOrganizationBillingAddress(id: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/organization/${id}/billingaddress/`, options: options });
    }

    /**
     * HTTP POST /v1/organization/{id}/billingaddress/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.updateOrganizationBillingAddress
     */
    updateOrganizationBillingAddress(id: UUID, address: AddressLocationData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/organization/${id}/billingaddress/`, data: address, options: options });
    }

    /**
     * HTTP POST /v1/organization/{id}/billingaddress/email/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.updateOrganizationBillingEmailAddress
     */
    updateOrganizationBillingEmailAddress(id: UUID, data: AddressLocationData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/organization/${id}/billingaddress/email/`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/organization/{id}/site/
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.createOrUpdateSite
     */
    createOrUpdateSite(id: UUID, site: CompanyData, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/organization/${id}/site/`, data: site, options: options });
    }

    /**
     * HTTP DELETE /v1/organization/{id}/site/{siteId}
     * Java method: com.kps.hhla.backend.rest.endpoints.OrganizationEndpoint.deleteSite
     */
    deleteSite(id: UUID, siteId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/organization/${id}/site/${siteId}`, options: options });
    }
}

export class PingApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/ping
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PingApiEndpoint.ping
     */
    ping(options?: RequestOptions): RestResponse<Payload<string>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/ping`, options: options });
    }
}

export class PriceEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/prices/
     * Java method: com.kps.hhla.backend.rest.endpoints.PriceEndpoint.createPrices
     */
    createPrices(prices: ExtendedPriceData[], options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ExtendedPriceData, ExtendedPriceData>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/prices/`, data: prices, options: options });
    }

    /**
     * HTTP GET /v1/prices/
     * Java method: com.kps.hhla.backend.rest.endpoints.PriceEndpoint.listPrices
     */
    listPrices(queryParams?: { relationId?: UUID; relationTimetableId?: UUID; terminalId?: UUID; }, options?: RequestOptions): RestResponse<Payload<ExtendedPriceData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/prices/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/prices/
     * Java method: com.kps.hhla.backend.rest.endpoints.PriceEndpoint.updatePrices
     */
    updatePrices(prices: ExtendedPriceData[], options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ExtendedPriceData, ExtendedPriceData>>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/prices/`, data: prices, options: options });
    }

    /**
     * HTTP PUT /v1/prices/{priceId}
     * Java method: com.kps.hhla.backend.rest.endpoints.PriceEndpoint.updatePrice
     */
    updatePrice(priceId: UUID, price: ExtendedPriceData, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ExtendedPriceData, ExtendedPriceData>>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/prices/${priceId}`, data: price, options: options });
    }
}

export class PricelistApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PricelistApiEndpoint.createPricelist
     */
    createPricelist(pricelistData: CreateOrUpdatePricelistRequestData, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/pricelist/`, data: pricelistData, options: options });
    }

    /**
     * HTTP GET /api/v1/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PricelistApiEndpoint.listPricelist
     */
    listPricelist(queryParams?: { scheduleId?: UUID; }, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/pricelist/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/v1/pricelist/weightclass/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PricelistApiEndpoint.listWeightClasses
     */
    listWeightClasses(queryParams?: { pricelistId?: UUID; }, options?: RequestOptions): RestResponse<Payload<WeightClassData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/pricelist/weightclass/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/v1/pricelist/weightclass/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PricelistApiEndpoint.updateWeightClass
     */
    updateWeightClass(data: UpdateWeightClassRequestData, options?: RequestOptions): RestResponse<Payload<WeightClassData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/pricelist/weightclass/`, data: data, options: options });
    }

    /**
     * HTTP POST /api/v1/pricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PricelistApiEndpoint.updatePricelist
     */
    updatePricelist(pricelistId: UUID, pricelistData: CreateOrUpdatePricelistRequestData, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/pricelist/${pricelistId}`, data: pricelistData, options: options });
    }

    /**
     * HTTP GET /api/v1/pricelist/{pricelistId}/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.PricelistApiEndpoint.getPricelist
     */
    getPricelist(pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<GetPricelistResponseData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/pricelist/${pricelistId}/`, options: options });
    }
}

export class PromotionEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/operator/promotion/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.PromotionEndpoint.createPromotion
     */
    createPromotion(data: PromotionData, options?: RequestOptions): RestResponse<Payload<PromotionData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/operator/promotion/`, data: data, options: options });
    }

    /**
     * HTTP PUT /v1/operator/promotion/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.PromotionEndpoint.updatePromotion
     */
    updatePromotion(data: PromotionData, options?: RequestOptions): RestResponse<Payload<PromotionData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/operator/promotion/`, data: data, options: options });
    }

    /**
     * HTTP DELETE /v1/operator/promotion/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.PromotionEndpoint.deletePromotion
     */
    deletePromotion(id: UUID, options?: RequestOptions): RestResponse<Payload<PromotionData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/operator/promotion/${id}`, options: options });
    }

    /**
     * HTTP GET /v1/operator/promotion/{id}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.PromotionEndpoint.getPromotion
     */
    getPromotion(id: UUID, options?: RequestOptions): RestResponse<Payload<PromotionData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/operator/promotion/${id}`, options: options });
    }
}

export class RegistrationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/registration/
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.register
     */
    register(data: RegistrationData, options?: RequestOptions): RestResponse<Payload<RegistrationResultData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/registration/`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/registration/disable
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.disable
     */
    disable(queryParams?: { companyId?: UUID; }, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/registration/disable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/registration/enable
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.enable
     */
    enable(queryParams?: { companyId?: UUID; }, options?: RequestOptions): RestResponse<Payload<CompanyData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/registration/enable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/registration/resetActivationLinkToken
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.resetActivationLinkToken
     */
    resetActivationLinkToken(queryParams?: { userId?: string; }, options?: RequestOptions): RestResponse<Payload<{ [index: string]: DateAsString }>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/registration/resetActivationLinkToken`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/registration/set-password
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.setPassword
     */
    setPassword(data: ResetPasswordRequestData, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/registration/set-password`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/registration/validate-companyname
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.validateCompanyName
     */
    validateCompanyName(validation: ValidateCredentialsData, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/registration/validate-companyname`, data: validation, options: options });
    }

    /**
     * HTTP POST /v1/registration/validate-username
     * Java method: com.kps.hhla.backend.rest.endpoints.registration.RegistrationEndpoint.validateUsername
     */
    validateUsername(validation: ValidateCredentialsData, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/registration/validate-username`, data: validation, options: options });
    }
}

export class RegularBusinessContractEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/businesscontract/relations/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.RegularBusinessContractEndpoint.getSpecialRateRelations
     */
    getSpecialRateRelations(options?: RequestOptions): RestResponse<Payload<SpecialRateSearchEntryData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/businesscontract/relations/`, options: options });
    }

    /**
     * HTTP DELETE /v1/businesscontract/{businessContractId}/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.RegularBusinessContractEndpoint.deleteBusinessContract
     */
    deleteBusinessContract(businessContractId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/businesscontract/${businessContractId}/`, options: options });
    }

    /**
     * HTTP PUT /v1/businesscontract/{businessContractId}/
     * Java method: com.kps.hhla.backend.rest.endpoints.offers.RegularBusinessContractEndpoint.updateBusinessContract
     */
    updateBusinessContract(businessContractId: UUID, data: RegularBusinessContractData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/businesscontract/${businessContractId}/`, data: data, options: options });
    }
}

export class RelationApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/relation
     * Java method: com.kps.hhla.backend.rest.endpoints.api.RelationApiEndpoint.updateRelationTimetableEntry
     */
    updateRelationTimetableEntry(data: UpdateRelationRequestData, options?: RequestOptions): RestResponse<Payload<UpdateRelationResponseData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/relation`, data: data, options: options });
    }
}

export class RelationSearchApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/search/relation/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.RelationSearchApiEndpoint.search
     */
    search(searchRequest: RelationSearchRequestData, options?: RequestOptions): RestResponse<Payload<RelationSearchResult>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/search/relation/`, data: searchRequest, options: options });
    }
}

export class RelationSearchEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/relations/search
     * Java method: com.kps.hhla.backend.rest.endpoints.search.RelationSearchEndpoint.search
     */
    search(searchRequest: RelationSearchRequestData, options?: RequestOptions): RestResponse<Payload<RelationSearchResult>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/relations/search`, data: searchRequest, options: options });
    }

    /**
     * HTTP GET /v1/relations/search/history
     * Java method: com.kps.hhla.backend.rest.endpoints.search.RelationSearchEndpoint.getSearchHistory
     */
    getSearchHistory(options?: RequestOptions): RestResponse<Payload<RelationSearchTrackingData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/relations/search/history`, options: options });
    }

    /**
     * HTTP GET /v1/relations/search/overview
     * Java method: com.kps.hhla.backend.rest.endpoints.search.RelationSearchEndpoint.listNextDepartures
     */
    listNextDepartures(queryParams?: { pageSize?: number; page?: number; }, options?: RequestOptions): RestResponse<Payload<RelationOverviewEntryData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/relations/search/overview`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/relations/search/sortOptions
     * Java method: com.kps.hhla.backend.rest.endpoints.search.RelationSearchEndpoint.getSortOptions
     */
    getSortOptions(options?: RequestOptions): RestResponse<Payload<SortOrderData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/relations/search/sortOptions`, options: options });
    }
}

export class ScheduleApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/schedule/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.ScheduleApiEndpoint.createSchedule
     */
    createSchedule(scheduleData: CreateOrUpdateScheduleRequestData, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ScheduleEntryData, { [index: string]: string }>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/schedule/`, data: scheduleData, options: options });
    }

    /**
     * HTTP GET /api/v1/schedule/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.ScheduleApiEndpoint.listSchedules
     */
    listSchedules(options?: RequestOptions): RestResponse<Payload<ScheduleData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/schedule/`, options: options });
    }

    /**
     * HTTP LOCK /api/v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.ScheduleApiEndpoint.archiveSchedule
     */
    archiveSchedule(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "LOCK", url: uriEncoding`api/v1/schedule/${scheduleId}`, options: options });
    }

    /**
     * HTTP DELETE /api/v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.ScheduleApiEndpoint.deleteSchedule
     */
    deleteSchedule(scheduleId: UUID, body: DeleteScheduleRequestData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/schedule/${scheduleId}`, data: body, options: options });
    }

    /**
     * HTTP GET /api/v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.ScheduleApiEndpoint.getSchedule
     */
    getSchedule(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<GetScheduleResponseData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/schedule/${scheduleId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.ScheduleApiEndpoint.updateSchedule
     */
    updateSchedule(scheduleId: UUID, scheduleData: CreateOrUpdateScheduleRequestData, options?: RequestOptions): RestResponse<Payload<ImportStatusSummaryData<ScheduleEntryData, { [index: string]: string }>>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/schedule/${scheduleId}`, data: scheduleData, options: options });
    }
}

export class SchedulePricelistEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * @deprecated
     * HTTP POST /v1/schedule/{scheduleId}/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.createPricelist
     */
    createPricelist(scheduleId: UUID, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/`, options: options });
    }

    /**
     * HTTP GET /v1/schedule/{scheduleId}/pricelist/
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.getPricelistNames
     */
    getPricelistNames(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<PagedResult<SchedulePricelistData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/`, options: options });
    }

    /**
     * HTTP GET /v1/schedule/{scheduleId}/pricelist/export/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.exportPricelist
     */
    exportPricelist(scheduleId: string, pricelistId: UUID, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/export/${pricelistId}`, options: options });
    }

    /**
     * HTTP GET /v1/schedule/{scheduleId}/pricelist/template
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.downloadTemplate
     */
    downloadTemplate(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/template`, options: options });
    }

    /**
     * HTTP DELETE /v1/schedule/{scheduleId}/pricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.deletePricelist
     */
    deletePricelist(scheduleId: string, pricelistId: UUID, options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/${pricelistId}`, options: options });
    }

    /**
     * HTTP GET /v1/schedule/{scheduleId}/pricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.getPricelist
     */
    getPricelist(scheduleId: string, pricelistId: UUID, queryParams?: { startTerminal?: string; endTerminal?: string; equipmentType?: UUID; page?: number; pageSize?: number; applyToReturnTour?: boolean; }, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/${pricelistId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/schedule/{scheduleId}/pricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.updatePricelist
     */
    updatePricelist(scheduleId: string, pricelistId: UUID, data: SchedulePricelistData, options?: RequestOptions): RestResponse<Payload<SchedulePricelistData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/${pricelistId}`, data: data, options: options });
    }

    /**
     * HTTP PATCH /v1/schedule/{scheduleId}/pricelist/{pricelistId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.updatePricelistProperties
     */
    updatePricelistProperties(scheduleId: string, pricelistId: UUID, queryParams?: { allInPricelist?: boolean; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PATCH", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/${pricelistId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/schedule/{scheduleId}/pricelist/{pricelistId}/weightclass/{weightClassId}
     * Java method: com.kps.hhla.backend.rest.endpoints.operator.SchedulePricelistEndpoint.updateWeightClass
     */
    updateWeightClass(scheduleId: string, pricelistId: UUID, weightClassId: UUID, data: UpdateWeightClassRequestData, options?: RequestOptions): RestResponse<Payload<WeightClassData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/schedule/${scheduleId}/pricelist/${pricelistId}/weightclass/${weightClassId}`, data: data, options: options });
    }
}

export class SendNotificationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/notifications/carrier/24hNotificationMessage
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.send24hNotificationMessage
     */
    send24hNotificationMessage(offerId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/24hNotificationMessage`, data: offerId, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/96hNotificationMessage
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.send96hNotificationMessage
     */
    send96hNotificationMessage(offerId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/96hNotificationMessage`, data: offerId, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/completeOutgoingOffer
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendCarrierNotificationToCompleteOutgoingOffer
     */
    sendCarrierNotificationToCompleteOutgoingOffer(offer: any, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/completeOutgoingOffer`, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/customPriceListWasCreated
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendCustomPriceListWasCreatedMessage
     */
    sendCustomPriceListWasCreatedMessage(queryParams?: { operatorUid?: string; userUid?: string; pricelistName?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/customPriceListWasCreated`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/mainLegRelationTimeChanged
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendNotificationMainLegRelationTimeChanged
     */
    sendNotificationMainLegRelationTimeChanged(offer: any, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/mainLegRelationTimeChanged`, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/offerArchivedNotification
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendOfferArchivedNotification
     */
    sendOfferArchivedNotification(offer: any, queryParams?: { archivedReason?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/offerArchivedNotification`, queryParams: queryParams, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/offerCancelMessage
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendOfferCancelMessageToCarrier
     */
    sendOfferCancelMessageToCarrier(offer: any, queryParams?: { cancelReason?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/offerCancelMessage`, queryParams: queryParams, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/carrier/offerComment
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendOfferComment
     */
    sendOfferComment(offer: any, queryParams?: { comment?: string; userUid?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/carrier/offerComment`, queryParams: queryParams, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/forgotPassword
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendForgotPasswordMail
     */
    sendForgotPasswordMail(token: PasswordTokenData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/forgotPassword`, data: token, options: options });
    }

    /**
     * HTTP POST /v1/notifications/operator/acceptMainLeg
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendOperatorNotificationToAcceptMainLeg
     */
    sendOperatorNotificationToAcceptMainLeg(offer: any, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/operator/acceptMainLeg`, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/operator/customPricelistRequest
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendCustomPricelistRequestNotification
     */
    sendCustomPricelistRequestNotification(queryParams?: { operatorUid?: string; carrierUid?: string; userUid?: string; firstRequest?: boolean; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/operator/customPricelistRequest`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/notifications/operator/customPricelistUpdateRequest
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendCustomPricelistUpdateRequestNotification
     */
    sendCustomPricelistUpdateRequestNotification(queryParams?: { operatorUid?: string; carrierUid?: string; userUid?: string; pricelistId?: UUID; firstRequest?: boolean; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/operator/customPricelistUpdateRequest`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/notifications/operator/offerCancelMessage
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendOfferCancelMessageToOperator
     */
    sendOfferCancelMessageToOperator(offer: any, queryParams?: { cancelReason?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/operator/offerCancelMessage`, queryParams: queryParams, data: offer, options: options });
    }

    /**
     * HTTP POST /v1/notifications/provider/completedIncomingOffer
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendContractorNotificationAboutCompletedIncomingOffer
     */
    sendContractorNotificationAboutCompletedIncomingOffer(firstLeg: any, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/provider/completedIncomingOffer`, data: firstLeg, options: options });
    }

    /**
     * HTTP POST /v1/notifications/registrationConfirmation
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendRegistrationConfirmationEmail
     */
    sendRegistrationConfirmationEmail(userData: UserData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/registrationConfirmation`, data: userData, options: options });
    }

    /**
     * HTTP POST /v1/notifications/support/registrationNotification
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendRegistrationNotificationEmail
     */
    sendRegistrationNotificationEmail(data: RegistrationResultData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/support/registrationNotification`, data: data, options: options });
    }

    /**
     * HTTP POST /v1/notifications/support/scheduleImportException
     * Java method: com.kps.hhla.backend.rest.endpoints.notification.SendNotificationEndpoint.sendScheduleImportExceptionNotification
     */
    sendScheduleImportExceptionNotification(offer: any, queryParams?: { operatorUid?: string; userUid?: string; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/notifications/support/scheduleImportException`, queryParams: queryParams, data: offer, options: options });
    }
}

export class StatusEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/healthstatus/
     * Java method: com.kps.hhla.backend.rest.endpoints.StatusEndpoint.getStatus
     */
    getStatus(options?: RequestOptions): RestResponse<any> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/healthstatus/`, options: options });
    }
}

export class SubscriptionEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/subscription/
     * Java method: com.kps.hhla.backend.rest.endpoints.SubscriptionEndpoint.bookSubscription
     */
    bookSubscription(subscription: BookedSubscriptionData, options?: RequestOptions): RestResponse<Payload<BookedSubscriptionData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/subscription/`, data: subscription, options: options });
    }

    /**
     * HTTP GET /v1/subscription/
     * Java method: com.kps.hhla.backend.rest.endpoints.SubscriptionEndpoint.listSubscriptions
     */
    listSubscriptions(options?: RequestOptions): RestResponse<Payload<SubscriptionData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/subscription/`, options: options });
    }

    /**
     * HTTP GET /v1/subscription/booked/
     * Java method: com.kps.hhla.backend.rest.endpoints.SubscriptionEndpoint.getBookedSubscriptions
     */
    getBookedSubscriptions(queryParams?: { activeOnly?: boolean; }, options?: RequestOptions): RestResponse<Payload<BookedSubscriptionData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/subscription/booked/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /v1/subscription/{subscriptionId}
     * Java method: com.kps.hhla.backend.rest.endpoints.SubscriptionEndpoint.cancelSubscription
     */
    cancelSubscription(subscriptionId: UUID, options?: RequestOptions): RestResponse<Payload<BookedSubscriptionData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/subscription/${subscriptionId}`, options: options });
    }
}

export class SurChargeEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP DELETE /v1/surcharges/
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.deleteSurcharges
     */
    deleteSurcharges(surcharges: SurChargeData[], options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/surcharges/`, data: surcharges, options: options });
    }

    /**
     * HTTP PUT /v1/surcharges/
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.uploadSurcharges
     */
    uploadSurcharges(request: UploadRelationSurchargeRequestData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/surcharges/`, data: request, options: options });
    }

    /**
     * HTTP GET /v1/surcharges/download
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.download
     */
    download(queryParams?: { scheduleId?: UUID; ownerType?: SurchargeOwnerType[]; }, options?: RequestOptions): RestResponse<Payload<BlobData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/surcharges/download`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /v1/surcharges/freeleg/
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.deleteFreeLegSurcharges
     */
    deleteFreeLegSurcharges(surcharges: UUID[], options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/surcharges/freeleg/`, data: surcharges, options: options });
    }

    /**
     * HTTP GET /v1/surcharges/freeleg/
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.listFreeLegSurcharges
     */
    listFreeLegSurcharges(queryParams?: { page?: number; pageSize?: number; sort?: string[]; terminalIds?: UUID[]; type?: SurChargeType[]; name?: string; }, options?: RequestOptions): RestResponse<Payload<PagedResult<FreeLegSurchargesEntryData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/surcharges/freeleg/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /v1/surcharges/freeleg/
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.uploadFreeLegSurcharges
     */
    uploadFreeLegSurcharges(surcharges: FreeLegSurchargesEntryData[], options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/surcharges/freeleg/`, data: surcharges, options: options });
    }

    /**
     * HTTP DELETE /v1/surcharges/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.deleteByScheduleId
     */
    deleteByScheduleId(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/surcharges/schedule/${scheduleId}`, options: options });
    }

    /**
     * HTTP GET /v1/surcharges/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.listScheduleSurcharges
     */
    listScheduleSurcharges(scheduleId: UUID, queryParams?: { page?: number; pageSize?: number; sort?: string[]; type?: SurChargeType[]; name?: string; startTerminal?: UUID[]; endTerminal?: UUID[]; }, options?: RequestOptions): RestResponse<Payload<PagedResult<ListRelationSurchargesEntryData>>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/surcharges/schedule/${scheduleId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /v1/surcharges/schedule/{scheduleId}/relation
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.listRelationsForSchedule
     */
    listRelationsForSchedule(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<GetScheduleResponseData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/surcharges/schedule/${scheduleId}/relation`, options: options });
    }

    /**
     * HTTP POST /v1/surcharges/upload
     * Java method: com.kps.hhla.backend.rest.endpoints.SurChargeEndpoint.upload
     */
    upload(data: BlobData, queryParams?: { scheduleId?: UUID; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/surcharges/upload`, queryParams: queryParams, data: data, options: options });
    }
}

export class SurchargeApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /api/v1/surcharge/relation/{relationId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.SurchargeApiEndpoint.createRelationSurcharge
     */
    createRelationSurcharge(relationId: UUID, surcharge: SurChargeData, options?: RequestOptions): RestResponse<Payload<UploadRelationSurchargeEntryData>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/surcharge/relation/${relationId}`, data: surcharge, options: options });
    }

    /**
     * HTTP GET /api/v1/surcharge/relation/{relationId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.SurchargeApiEndpoint.listRelationSurcharges
     */
    listRelationSurcharges(relationId: UUID, options?: RequestOptions): RestResponse<Payload<UploadRelationSurchargeEntryData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/surcharge/relation/${relationId}`, options: options });
    }

    /**
     * HTTP GET /api/v1/surcharge/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.SurchargeApiEndpoint.listScheduleSurcharges
     */
    listScheduleSurcharges(scheduleId: UUID, options?: RequestOptions): RestResponse<Payload<UploadRelationSurchargeEntryData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/surcharge/schedule/${scheduleId}`, options: options });
    }

    /**
     * HTTP POST /api/v1/surcharge/schedule/{scheduleId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.SurchargeApiEndpoint.uploadSurcharges
     */
    uploadSurcharges(scheduleId: UUID, request: UploadRelationSurchargeRequestData, options?: RequestOptions): RestResponse<Payload<UploadRelationSurchargeEntryData[]>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/v1/surcharge/schedule/${scheduleId}`, data: request, options: options });
    }

    /**
     * HTTP DELETE /api/v1/surcharge/{surchargeId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.SurchargeApiEndpoint.deleteSurcharge
     */
    deleteSurcharge(surchargeId: UUID, options?: RequestOptions): RestResponse<Payload<UploadRelationSurchargeEntryData>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/v1/surcharge/${surchargeId}`, options: options });
    }

    /**
     * HTTP PUT /api/v1/surcharge/{surchargeId}
     * Java method: com.kps.hhla.backend.rest.endpoints.api.SurchargeApiEndpoint.updateSurcharge
     */
    updateSurcharge(surchargeId: UUID, request: SurChargeData, options?: RequestOptions): RestResponse<Payload<UploadRelationSurchargeEntryData>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/v1/surcharge/${surchargeId}`, data: request, options: options });
    }
}

export class TerminalSearchApiEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /api/v1/terminal/
     * Java method: com.kps.hhla.backend.rest.endpoints.api.TerminalSearchApiEndpoint.search
     */
    search(queryParams?: { searchTerm?: string; isSeaTerminal?: boolean; isRailTerminal?: boolean; page?: number; pageSize?: number; }, options?: RequestOptions): RestResponse<Payload<TerminalSearchResultData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/v1/terminal/`, queryParams: queryParams, options: options });
    }
}

export class ValidationEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP GET /v1/validation/vatnumber
     * Java method: com.kps.hhla.backend.rest.endpoints.ValidationEndpoint.get
     */
    get(queryParams?: { isoCode?: string; vatId?: string; checkExisting?: boolean; }, options?: RequestOptions): RestResponse<Payload<VatValidationResultData>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/validation/vatnumber`, queryParams: queryParams, options: options });
    }
}

export class ViewGroupEndpointClient {

    constructor(protected httpClient: HttpClient) {
    }

    /**
     * HTTP POST /v1/viewgroup/
     * Java method: com.kps.hhla.backend.rest.endpoints.ViewGroupEndpoint.createOrUpdateViewGroup
     */
    createOrUpdateViewGroup(data: CreateOrUpdateViewGroupRequestData, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`v1/viewgroup/`, data: data, options: options });
    }

    /**
     * HTTP GET /v1/viewgroup/
     * Java method: com.kps.hhla.backend.rest.endpoints.ViewGroupEndpoint.listViewGroups
     */
    listViewGroups(options?: RequestOptions): RestResponse<Payload<ViewGroupData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/viewgroup/`, options: options });
    }

    /**
     * HTTP GET /v1/viewgroup/users/
     * Java method: com.kps.hhla.backend.rest.endpoints.ViewGroupEndpoint.listAvailableUsers
     */
    listAvailableUsers(queryParams?: { searchTerm?: string; }, options?: RequestOptions): RestResponse<Payload<UserData[]>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`v1/viewgroup/users/`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /v1/viewgroup/{viewGroupId}
     * Java method: com.kps.hhla.backend.rest.endpoints.ViewGroupEndpoint.deleteViewGroup
     */
    deleteViewGroup(viewGroupId: UUID, queryParams?: { replacementViewGroupId?: UUID; }, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`v1/viewgroup/${viewGroupId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP PUT /v1/viewgroup/{viewGroupId}/offer/{offerId}
     * Java method: com.kps.hhla.backend.rest.endpoints.ViewGroupEndpoint.setOfferViewGroup
     */
    setOfferViewGroup(viewGroupId: UUID, offerId: UUID, options?: RequestOptions): RestResponse<Payload<void>> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`v1/viewgroup/${viewGroupId}/offer/${offerId}`, options: options });
    }
}

export interface AbstractDataTransferObject {
}

export interface Bean extends AbstractDataTransferObject, Serializable {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
}

export class AbstractLegData implements Bean, Clonable<AbstractLegData> {
    bookableFrom?: DateAsString;
    bookableTo?: DateAsString;
    bookedAt?: DateAsString;
    bookingImpediments?: BookingImpediment[];
    company?: CompanyData;
    containerAddresses?: { [index: string]: ContainerAddressData };
    createdAt?: DateAsString;
    depotDropoffLeg?: boolean;
    depotPickupLeg?: boolean;
    distance?: DistanceData;
    duration?: DurationData;
    endTimestamp?: DateAsString;
    endWaypointId?: UUID;
    firstLeg?: boolean;
    freeleg?: boolean;
    id?: UUID;
    lastLeg?: boolean;
    mainLeg?: boolean;
    modifiedAt?: DateAsString;
    originalPrice?: number;
    originalProviderId?: UUID;
    price?: ExtendedPriceData;
    /**
     * @deprecated
     */
    requiredTimeOfArrival?: DateAsString;
    /**
     * @deprecated
     */
    requiredTimeOfDeparture?: DateAsString;
    requiredTimeRangeOfArrival?: any;
    requiredTimeRangeOfDeparture?: any;
    sameAddressForAllEquipments?: boolean;
    startTimestamp?: DateAsString;
    startWaypointId?: UUID;
    status?: OfferStatus;
    surCharges?: SurChargeData[];
    surchargeTotalPrice?: ExtendedPriceData;
    totalPrice?: ExtendedPriceData;
    type?: LegType;
    volumeOfSales?: PriceData;
}

export class AbstractMessageData {
    createdAt?: DateAsString;
    id?: UUID;
    locale?: Locale;
    readStatus?: MessageReadStatus;
    recipient?: string;
    reference?: string;
    sender?: string;
    subject?: { [index: string]: string };
    text?: { [index: string]: string };
}

export class AbstractPricelistEntryData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "equipmentTypeCode")
    equipmentTypeCode?: string;
    @Reflect.metadata("propertyName", "value")
    value?: number;
    @Reflect.metadata("propertyName", "weightClassMaxWeight")
    weightClassMaxWeight?: number;
}

export class AbstractPricelistRequestData<E> implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "defaultPrice")
    defaultPrice?: number;
    @Reflect.metadata("propertyName", "scheduleId")
    scheduleId?: UUID;
}

export interface AddressLocaitonDataListPayloadResponse extends Payload<AddressLocationData[]> {
    data?: AddressLocationData[];
}

export class LocationData implements Bean, Comparable<LocationData> {
    createdAt?: DateAsString;
    distance?: DistanceData;
    equipmentOwners?: EquipmentOwnerData[];
    id?: UUID;
    location?: GeoLocationData;
    modifiedAt?: DateAsString;
    name?: string;
    price?: PriceData;
    type?: LocationType;
    uid?: string;
}

export class AddressLocationData extends LocationData {
    addition?: string;
    city?: string;
    comment?: string;
    companyName?: string;
    contactPerson?: PersonData;
    country?: CountryData;
    countryIsoCode?: string;
    customAddress?: boolean;
    email?: string;
    fax?: string;
    isDepot?: boolean;
    isTerminal?: boolean;
    phone?: string;
    postalCode?: string;
    state?: string;
    streetName?: string;
    streetNumber?: string;
    timeRange?: any;
    vatId?: string;
}

export class AddressLocationRequestData extends AddressLocationData {
    /**
     * @deprecated
     */
    point?: GeoLocationData;
    terminalId?: UUID;
}

export class AllowedOfferActionsData implements Bean {
    accept?: boolean;
    cancel?: boolean;
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    skip?: boolean;
}

export class AlternativeRelationSearchRequestData implements Bean {
    createdAt?: DateAsString;
    fromDate?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    offerId?: UUID;
    onlyIncludeRelationsWithFreeCapacities?: boolean;
    page?: number;
    pageSize?: number;
    toDate?: DateAsString;
}

export interface AnnotatedElement {
    annotations?: Annotation[];
    declaredAnnotations?: Annotation[];
}

export interface AnnotatedType extends AnnotatedElement {
    annotatedOwnerType?: AnnotatedType;
    type?: Type;
}

export interface Annotation {
}

export interface Arrival {
    place?: Place__1;
    time?: string;
}

export class BlobData implements Bean {
    content?: string;
    createdAt?: DateAsString;
    empty?: boolean;
    filename?: string;
    id?: UUID;
    mimeType?: string;
    modifiedAt?: DateAsString;
}

export class AttachmentData extends BlobData {
    name?: string;
}

export class AuthenticationData implements Bean {
    createdAt?: DateAsString;
    expectedRoles?: UserRole[];
    id?: UUID;
    modifiedAt?: DateAsString;
    password?: string;
    plainText?: boolean;
    username?: string;
}

export class AuthenticationResponseData {
    company?: CompanyData;
    createdAt?: DateAsString;
    firstName?: string;
    id?: string;
    inboxId?: string;
    lastName?: string;
    phoneNumber?: string;
    preferredLocale?: Locale;
    profilePicture?: string;
    roles?: UserRole[];
    useSimpleSearch?: boolean;
    userId?: string;
}

export class BaseOfferData implements Bean {
    businessPartnerReferenceNumber?: string;
    containers?: ContainerData[];
    continentalTransport?: boolean;
    createdAt?: DateAsString;
    email?: string;
    firstLeg?: FreeLegData;
    hasFirstLeg?: boolean;
    hasLastLeg?: boolean;
    hazardGoods?: boolean;
    id?: UUID;
    lastLeg?: FreeLegData;
    legs?: AbstractLegData[];
    maritimeExport?: boolean;
    maritimeImport?: boolean;
    modeOfTransportation?: ModeOfTransportation;
    modifiedAt?: DateAsString;
    name?: string;
    phone?: string;
    provider?: CompanyData;
    referenceNumber?: string;
    regularBusinessContract?: RegularBusinessContractData;
    viewGroup?: ViewGroupData;
    waypoints?: AddressLocationData[];
}

export class BasicBookingExportData implements Bean {
    bookingDate?: DateAsString;
    bookingNumber?: string;
    createdAt?: DateAsString;
    customerCompanyName?: string;
    departureAt?: DateAsString;
    end?: LocationData;
    equipmentType?: string;
    exportStatus?: ExportStatus;
    id?: UUID;
    legBookingNumber?: string;
    legId?: UUID;
    legType?: LegType;
    modifiedAt?: DateAsString;
    offerId?: UUID;
    quantity?: number;
    start?: LocationData;
}

export class BasicGetOffersRequestData {
    @Reflect.metadata("propertyName", "bookingFrom")
    bookingFrom?: DateAsString;
    @Reflect.metadata("propertyName", "bookingTo")
    bookingTo?: DateAsString;
    @Reflect.metadata("propertyName", "carrierExportStatuses")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("isEnumType", true)
    @Reflect.metadata("propertyArrayType", () => ExportStatus)
    carrierExportStatuses?: ExportStatus[];
    @Reflect.metadata("propertyName", "offerId")
    offerId?: UUID;
    @Reflect.metadata("propertyName", "page")
    page?: number;
    @Reflect.metadata("propertyName", "pageSize")
    pageSize?: number;
    @Reflect.metadata("propertyName", "providerExportStatuses")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("isEnumType", true)
    @Reflect.metadata("propertyArrayType", () => ExportStatus)
    providerExportStatuses?: ExportStatus[];
    @Reflect.metadata("propertyName", "searchTerm")
    searchTerm?: string;
}

export class BasicRelationData implements Bean {
    createdAt?: DateAsString;
    end?: AddressLocationData;
    id?: UUID;
    modifiedAt?: DateAsString;
    operator?: CompanyData;
    relationId?: UUID;
    start?: AddressLocationData;
}

export class BillingData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    invoiceId?: UUID;
    modifiedAt?: DateAsString;
    month?: number;
    year?: number;
}

export class BlobMetaData implements Bean {
    contentType?: string;
    createdAt?: DateAsString;
    filename?: string;
    folderPath?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    size?: number;
    url?: string;
}

export class BookedSubscriptionData implements Bean {
    active?: boolean;
    bookedAt?: DateAsString;
    createdAt?: DateAsString;
    free?: boolean;
    id?: UUID;
    maxSites?: number;
    maxUsers?: number;
    modifiedAt?: DateAsString;
    paymentInterval?: SubscriptionPaymentInterval;
    price?: number;
    subscriptionEnd?: DateAsString;
    subscriptionStart?: DateAsString;
    trialPeriodEnd?: DateAsString;
    trialPeriodStart?: DateAsString;
    uid?: string;
}

export class BookingExportAddressData {
    additionalInfo?: string;
    city?: string;
    country?: string;
    depot?: boolean;
    from?: DateAsString;
    name?: string;
    postalCode?: string;
    specialEquipment?: string;
    street?: string;
    streetNumber?: string;
    to?: DateAsString;
}

export class BookingExportRequestData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    includeDocuments?: boolean;
    legIds?: UUID[];
    modifiedAt?: DateAsString;
}

export class BookingExportResultData implements Bean {
    bookings?: PagedResult<BasicBookingExportData>;
    createdAt?: DateAsString;
    defaultFromDate?: DateAsString;
    defaultToDate?: DateAsString;
    id?: UUID;
    lastExportedAt?: DateAsString;
    modifiedAt?: DateAsString;
    numberOfNewBookingsSinceLastExport?: number;
}

export interface BookingFavoriteData extends RelationSearchTrackingData {
    companyId?: UUID;
    dropOffDepotId?: UUID;
    favoriteId?: UUID;
    name?: string;
    pickUpDepotId?: UUID;
    private?: boolean;
    route?: string;
    sourceBookingId?: UUID;
    sourceBookingNumber?: string;
    transportDirection?: TransportDirectionType;
}

export class BookingTemplateCargoData implements AbstractDataTransferObject {
    commodityCode?: string;
    description?: string;
}

export interface CreateBookingTemplateRequest extends Bean {
    name?: string;
    offerId?: UUID;
    private?: boolean;
}

export class BookingTemplateData implements CreateBookingTemplateRequest {
    arrival?: AddressLocationData;
    arrivalTerminal?: LocationData;
    cargos?: BookingTemplateCargoData[];
    createdAt?: DateAsString;
    customsOffice?: string;
    customsProcess?: CustomsProcess;
    customsRecipientAddress?: AddressLocationData;
    departure?: AddressLocationData;
    departureTerminal?: LocationData;
    equipmentType?: EquipmentTypeData;
    firstLegContainerDepot?: ContainerDepotLocationData;
    hazardClassification?: HazardClassificationData;
    id?: UUID;
    lastLegContainerDepot?: ContainerDepotLocationData;
    modifiedAt?: DateAsString;
    name?: string;
    offerId?: UUID;
    overseasPort?: string;
    private?: boolean;
    transportCountryOfDestination?: CountryData;
    transportCountryOfOrigin?: CountryData;
    weightClass?: WeightClassData;
}

export class CancelOfferRequestData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    reason?: OfferCancellationReason;
    text?: { [index: string]: string };
    type?: CancellationType;
}

export class ChatMessageData extends AbstractMessageData {
}

export interface Class<T> extends Serializable, GenericDeclaration, Type, AnnotatedElement, OfField<Class<any>>, Constable {
}

export interface ClassObject {
    paramValue?: any;
    paramValueType?: Class<any>;
}

export interface Clonable<T> extends Serializable {
}

export interface Cloneable {
}

export interface Co2EmissionComparisonResult {
    absoluteEmissionSavings?: number;
    emissionSavingsInPercent?: number;
    emissionsByTrainInTons?: number;
    emissionsByTruckInTons?: number;
}

export class CompanyData implements Bean, Comparable<CompanyData> {
    acceptDataProtectionPolicy?: boolean;
    acceptGTC?: boolean;
    acceptedDataProtectionPolicy?: DateAsString;
    acceptedGTC?: DateAsString;
    activatedAt?: DateAsString;
    active?: boolean;
    address?: AddressLocationData;
    allInProvider?: boolean;
    allowList?: CompanyData[];
    billingAddress?: AddressLocationData;
    blockList?: CompanyData[];
    closingThreshold?: number;
    contactEmailAddress?: string;
    contactPerson?: UserData;
    createdAt?: DateAsString;
    /**
     * @deprecated for removal
     */
    customerNumber?: string;
    deleted?: boolean;
    disabledAt?: DateAsString;
    enabledAt?: DateAsString;
    generalTermsDocument?: string;
    id?: UUID;
    logo?: BlobData;
    logoUrl?: string;
    modifiedAt?: DateAsString;
    name?: string;
    organization?: OrganizationData;
    organizationId?: UUID;
    paymentInfo?: PaymentInfoData;
    preferredLocale?: Locale;
    rating?: CompanyRating;
    sendMailActive?: boolean;
    type?: CompanyType;
    uid?: string;
    userCount?: number;
    users?: UserData[];
}

export interface CompanyDataPayloadResponse extends Payload<CompanyData> {
    data?: CompanyData;
}

export interface Comparable<T> {
}

export interface Constable {
}

export class ContainerAddressData extends AddressLocationData {
    desaddle?: boolean;
    specialEquipment?: SpecialEquipment;
}

export class ContainerData implements Bean {
    allowedConfigurations?: EquipmentConfigurationData[];
    calculatedTotalWeight?: number;
    comments?: string[];
    configuration?: EquipmentConfigurationData;
    createdAt?: DateAsString;
    customsOffice?: string;
    customsProcess?: CustomsProcess;
    customsRecipientAddress?: AddressLocationData;
    customsRegistrationNumbers?: CustomsNumberData[];
    empty?: boolean;
    equipmentNumber?: string;
    grossWeight?: number;
    hasDivergingCustomsRecipient?: boolean;
    hazardGoods?: boolean;
    height?: number;
    id?: UUID;
    isoNumber?: string;
    length?: number;
    loadingUnits?: LoadingUnitData[];
    mandatoryFields?: MandatoryFieldsData;
    modifiedAt?: DateAsString;
    netWeight?: number;
    overseasPort?: string;
    pin?: string;
    profile?: string;
    referenceNumber?: string;
    requiredUnits?: number;
    sealNumber?: string;
    shipClosingTime?: DateAsString;
    shipName?: string;
    shippersOwn?: string;
    shippingCompany?: string;
    surCharges?: SurChargeData[];
    tareWeight?: number;
    tareWeightSelected?: boolean;
    totalWeight?: number;
    totalWeightOverridden?: boolean;
    transportCountryOfDestination?: CountryData;
    transportCountryOfOrigin?: CountryData;
    type?: EquipmentTypeData;
    waypointInfos?: WaypointInfoData[];
    weightClass?: WeightClassData;
    width?: number;
}

export class ContainerDepotLocationData extends AddressLocationData {
    active?: boolean;
    additionalLine?: string;
    description?: string;
    isContainerDepot?: boolean;
}

export class ContainerDepotResultData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    results?: LocationData[];
}

export interface ConvertedPrice {
    currency?: string;
    type?: string;
    value?: number;
}

export class CountryData implements Bean, Comparable<CountryData> {
    createdAt?: DateAsString;
    id?: UUID;
    isoCode?: string;
    modifiedAt?: DateAsString;
    name?: string;
    visibleForBookings?: boolean;
    visibleForRegistration?: boolean;
}

export class CreateFollowUpScheduleResponseData implements AbstractDataTransferObject {
    pricelistId?: UUID;
    relationSurchargesCreated?: boolean;
    scheduleId?: UUID;
}

export class CreateLegPriceRequestData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "baseUnit")
    @Reflect.metadata("isEnumType", true)
    baseUnit?: SurchargeBaseUnit;
    @Reflect.metadata("propertyName", "depotId")
    depotId?: UUID;
    @Reflect.metadata("propertyName", "hidden")
    hidden?: boolean;
    @Reflect.metadata("propertyName", "maxDistance")
    maxDistance?: number;
    @Reflect.metadata("propertyName", "pricelistId")
    pricelistId?: UUID;
    @Reflect.metadata("propertyName", "relative")
    relative?: boolean;
    @Reflect.metadata("propertyName", "surchargeName")
    surchargeName?: string;
    @Reflect.metadata("propertyName", "surchargeType")
    @Reflect.metadata("isEnumType", true)
    surchargeType?: SurChargeType;
    @Reflect.metadata("propertyName", "type")
    @Reflect.metadata("isEnumType", true)
    type?: LegPriceType;
    @Reflect.metadata("propertyName", "validFrom")
    validFrom?: DateAsString;
    @Reflect.metadata("propertyName", "validTo")
    validTo?: DateAsString;
    @Reflect.metadata("propertyName", "value")
    value?: number;
}

export class CreateOfferApiRequestData implements Bean {
    complete?: boolean;
    containersQuantity?: number;
    createdAt?: DateAsString;
    documentsToUpload?: BlobData[];
    equipmentType?: EquipmentTypeData;
    hazardClass?: HazardClassificationData;
    id?: UUID;
    legs?: AbstractLegData[];
    messageForOperator?: string;
    modeOfTransportation?: ModeOfTransportation;
    modifiedAt?: DateAsString;
    provider?: CompanyData;
    relationPriceId?: UUID;
    waypoints?: AddressLocationData[];
    weightClass?: WeightClassData;
}

export class CreateOfferRequestData extends BaseOfferData {
    bookingTemplateId?: UUID;
    relationPriceId?: UUID;
}

export class CreateOrUpdatePricelistEntryData extends AbstractPricelistEntryData {
    @Reflect.metadata("propertyName", "arrivalTerminalId")
    arrivalTerminalId?: UUID;
    @Reflect.metadata("propertyName", "departureTerminalId")
    departureTerminalId?: UUID;
}

export class CreateOrUpdatePricelistRequestData extends AbstractPricelistRequestData<CreateOrUpdatePricelistEntryData> {
    @Reflect.metadata("propertyName", "entries")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => CreateOrUpdatePricelistEntryData)
    entries?: CreateOrUpdatePricelistEntryData[];
    @Reflect.metadata("propertyName", "publish")
    publish?: boolean;
}

export class CreateOrUpdateScheduleRequestData implements Bean {
    @Reflect.metadata("propertyName", "createdAt")
    createdAt?: DateAsString;
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "modifiedAt")
    modifiedAt?: DateAsString;
    @Reflect.metadata("propertyName", "name")
    name?: string;
    @Reflect.metadata("propertyName", "relations")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => RelationTimetableImportRowData)
    relations?: RelationTimetableImportRowData[];
}

export class ViewGroupData implements Bean {
    contactEmailAddress?: string;
    createdAt?: DateAsString;
    description?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
    users?: UserData[];
}

export class CreateOrUpdateViewGroupRequestData extends ViewGroupData {
}

export class CreditCardData implements Bean, Comparable<CreditCardData> {
    checksum?: string;
    company?: CompanyData;
    companyName?: string;
    country?: CountryData;
    createdAt?: DateAsString;
    expiryMonth?: number;
    expiryYear?: number;
    firstName?: string;
    firstSix?: string;
    id?: UUID;
    lastFour?: string;
    lastName?: string;
    modifiedAt?: DateAsString;
    number?: string;
    numbersInBetween?: number;
    phone?: string;
    postalCode?: string;
    vatId?: string;
}

export class CustomsNumberData implements AbstractDataTransferObject {
    number?: string;
    stringRepresentation?: string;
    type?: CustomsNumberType;
}

export interface Daily extends NotificationSchedule {
    time?: DateAsString;
}

/**
 * @deprecated
 */
export interface Days {
    fr?: boolean;
    mo?: boolean;
    sa?: boolean;
    su?: boolean;
    th?: boolean;
    tu?: boolean;
    we?: boolean;
}

export class RelationData extends BasicRelationData {
    co2Saving?: number;
    dateOfArrivalConcrete?: DateAsString;
    dateOfClosingConcrete?: DateAsString;
    dayOfArrivalConcrete?: DateAsString;
    dayOfClosingConcrete?: DateAsString;
    distance?: DistanceData;
    distanceValue?: number;
    duration?: DurationData;
    endTimeZoneId?: string;
    excludedDangerousGoodsClasses?: string[];
    promotable?: boolean;
    promotion?: PromotionData;
    recurring?: boolean;
    recurringEntryId?: string;
    schedule?: ScheduleData;
    scheduleName?: string;
    scheduleYear?: string;
    startTimeZoneId?: string;
    statistics?: RelationStatisticsData;
    supportsContainer?: boolean;
    supportsTrailer?: boolean;
    timeOfArrival?: DateAsString;
    timeOfClosing?: DateAsString;
    totalExternalUnits?: number;
    totalExternalWeightCapacity?: number;
    totalUnits?: number;
    totalWeightCapacity?: number;
    trainNumber?: string;
    transportCarriers?: string[];
    validFrom?: DateAsString;
    validTo?: DateAsString;
    volumeOfSales?: PriceData;
    weekdayOfArrival?: number;
    weekdayOfClosing?: number;
}

export class RelationUpdateRequestData extends RelationData {
    autoAcceptThreshold?: number;
    originalArrivalDateTime?: DateAsString;
    originalClosingDateTime?: DateAsString;
    price?: ExtendedPriceData;
    prices?: ExtendedPriceData[];
    promotionId?: UUID;
}

export class DeleteRelationTimetableEntryRequestData extends RelationUpdateRequestData {
    reason?: string;
    specialScheduleId?: UUID;
}

export class DeleteScheduleRequestData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    reason?: string;
    softDelete?: boolean;
}

export interface Departure {
    place?: Place;
    time?: string;
}

export interface DepotDataListPayloadResponse extends Payload<ContainerDepotLocationData[]> {
    data?: ContainerDepotLocationData[];
}

export class DepotTransportLegData extends AbstractLegData {
}

export class PriceData implements Bean, Comparable<PriceData> {
    createdAt?: DateAsString;
    currencyCode?: string;
    customPrice?: boolean;
    id?: UUID;
    maxWeightExceeded?: boolean;
    modifiedAt?: DateAsString;
    promotionPrice?: boolean;
    value?: number;
}

export class DiscountData extends PriceData {
    relative?: boolean;
}

export class DistanceData implements Bean, Comparable<DistanceData> {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    unit?: DistanceUnit;
    value?: number;
}

export interface DistanceUnit {
    code?: string;
    name?: string;
}

export class DiumData implements Bean, Comparable<DiumData> {
    createdAt?: DateAsString;
    diumNumber?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    terminalName?: string;
}

export class DownloadBookingExportData implements Bean {
    arrivalAt?: DateAsString;
    billingAddress?: AddressLocationData;
    billingAddressCompanyName?: string;
    billingAddressContactPerson?: PersonData;
    billingAddressEmail?: string;
    bookingDate?: DateAsString;
    bookingNumber?: string;
    co2Saving?: number;
    containerDepot?: string;
    containers?: ContainerData[];
    createdAt?: DateAsString;
    customerCompany?: CompanyData;
    customerCompanyEmail?: string;
    customerCompanyName?: string;
    customerContactPerson?: UserData;
    customerContactPersonEmail?: string;
    customerContactPersonName?: string;
    customerContactPersonPhone?: string;
    customerReferenceNumber?: string;
    departureAt?: DateAsString;
    depotDropOff?: DownloadBookingExportData;
    depotDropOffWaypoints?: WaypointInfoData[];
    depotPickup?: DownloadBookingExportData;
    depotPickupWaypoints?: WaypointInfoData[];
    depotPrice?: number;
    depotRequiredTimeRangeOfArrival?: Range<DateAsString>;
    depotRequiredTimeRangeOfDeparture?: Range<DateAsString>;
    end?: LocationData;
    endTerminal?: LocationData;
    equipmentNumber?: string;
    equipmentSummary?: string;
    equipmentType?: string;
    exportTimestamp?: DateAsString;
    firstLeg?: FreeLegData;
    firstLegAcceptedAt?: DateAsString;
    firstLegBookingNumber?: string;
    firstLegDepot?: ContainerDepotLocationData;
    firstLegDepotTimeRange?: Range<DateAsString>;
    firstLegDepotWaypoints?: WaypointInfoData[];
    firstLegPrice?: number;
    firstLegSpecialEquipment?: string;
    firstLegWaypoints?: WaypointInfoData[];
    hasDocuments?: boolean;
    hazardCode?: string;
    hazardGoods?: boolean;
    id?: UUID;
    lastLeg?: FreeLegData;
    lastLegAcceptedAt?: DateAsString;
    lastLegBookingNumber?: string;
    lastLegDepot?: ContainerDepotLocationData;
    lastLegDepotTimeRange?: Range<DateAsString>;
    lastLegDepotWaypoints?: WaypointInfoData[];
    lastLegPrice?: number;
    lastLegSpecialEquipment?: string;
    lastLegWaypoints?: WaypointInfoData[];
    legBookingNumber?: string;
    legType?: LegType;
    mainEmptyLegEndWaypoints?: WaypointInfoData[];
    mainEmptyLegStartWaypoints?: WaypointInfoData[];
    mainLegAcceptedAt?: DateAsString;
    mainLegBookingNumber?: string;
    mainLegEmptyExport?: DownloadBookingExportData;
    mainLegEmptyImport?: DownloadBookingExportData;
    mainLegEndWaypoints?: WaypointInfoData[];
    mainLegPrice?: number;
    mainLegStartWaypoints?: WaypointInfoData[];
    modeOfTransportation?: ModeOfTransportation;
    modifiedAt?: DateAsString;
    offerId?: UUID;
    position?: number;
    price?: number;
    priceTariffType?: string;
    providerCompany?: CompanyData;
    providerCompanyName?: string;
    providerContactPerson?: UserData;
    providerContactPersonEmail?: string;
    providerContactPersonName?: string;
    providerContactPersonPhone?: string;
    providerReferenceNumber?: string;
    relationTransportModes?: string;
    roundTripPrice?: number;
    start?: LocationData;
    startTerminal?: LocationData;
    status?: BookingStatus;
    surcharges?: LocalizedSurChargeData[];
    surchargesPrice?: number;
    totalPrice?: number;
    totalWeight?: number;
    transportPrice?: number;
}

export interface Duration extends TemporalAmount, Comparable<Duration>, Serializable {
}

export class DurationData implements Bean, Comparable<DurationData> {
    createdAt?: DateAsString;
    days?: number;
    hours?: number;
    id?: UUID;
    minutes?: number;
    modifiedAt?: DateAsString;
    seconds?: number;
}

export class EntryPriceData {
    equipmentTypeId?: UUID;
    relationId?: UUID;
    value?: number;
    weightClassId?: UUID;
}

export class EquipmentConfigurationData implements Bean {
    configurationType?: EquipmentConfigurationType;
    createdAt?: DateAsString;
    default?: boolean;
    height?: number;
    highCube?: boolean;
    id?: UUID;
    isoCode?: string;
    isoGroupCode?: string;
    length?: number;
    maxWeightGross?: number;
    modifiedAt?: DateAsString;
    name?: string;
    order?: number;
    palletWide?: boolean;
    tareWeight?: number;
    width?: number;
}

export class EquipmentOwnerData implements Bean, Comparable<EquipmentOwnerData> {
    active?: boolean;
    containerDepots?: LocationData[];
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
    type?: CompanyType;
    uid?: string;
}

export interface EquipmentOwnerDataListPayloadResponse extends Payload<EquipmentOwnerData[]> {
    data?: EquipmentOwnerData[];
}

export class EquipmentTypeData implements Bean, Comparable<EquipmentTypeData> {
    @Reflect.metadata("propertyName", "code")
    code?: string;
    @Reflect.metadata("propertyName", "createdAt")
    createdAt?: DateAsString;
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "modifiedAt")
    modifiedAt?: DateAsString;
    @Reflect.metadata("propertyName", "name")
    name?: string;
    @Reflect.metadata("propertyName", "requiredUnits")
    requiredUnits?: number;
}

export class ExtendedPriceData extends PriceData {
    country?: CountryData;
    customerOrganizations?: OrganizationData[];
    equipmentType?: EquipmentTypeData;
    firstLegAddress?: AddressLocationData;
    hazardClassification?: HazardClassificationData;
    kvRate?: boolean;
    lastLegAddress?: AddressLocationData;
    mainLegRate?: number;
    maxDistance?: number;
    name?: string;
    owner?: CompanyData;
    postalCode?: string;
    pricelistId?: UUID;
    promotion?: PromotionData;
    regularBusinessContractId?: UUID;
    relation?: RelationData;
    relationTimetableEntry?: RelationData;
    specialRate?: boolean;
    strikeThroughPrice?: number;
    terminal?: TerminalData;
    type?: PriceType;
    validFrom?: DateAsString;
    validTo?: DateAsString;
    weightClass?: WeightClassData;
}

export interface Fare {
    convertedPrice?: ConvertedPrice;
    id?: string;
    name?: string;
    paymentMethods?: string[];
    price?: Price;
    reason?: string;
}

export class FeatureFlagData implements AbstractDataTransferObject {
    enable?: boolean;
    uid?: string;
}

export interface FeatureFlagToggledQueueMessage extends QueueMessage {
    featureFlagName?: string;
    toggleAction?: ToggleAction;
}

export class FileData implements Bean {
    content?: any;
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
}

export class FileRequestData implements Bean {
    content?: any;
    createdAt?: DateAsString;
    id?: UUID;
    localFilePath?: string;
    modifiedAt?: DateAsString;
    overwrite?: boolean;
    remoteFilename?: string;
    remoteFolder?: string;
    secure?: boolean;
}

export class FindLegCriteriaData implements Bean {
    bookingFrom?: DateAsString;
    bookingTo?: DateAsString;
    carrierExportStatuses?: ExportStatus[];
    companyId?: UUID;
    createdAt?: DateAsString;
    id?: UUID;
    legTypes?: LegType[];
    modifiedAt?: DateAsString;
    offerId?: UUID;
    offerStates?: OfferStatus[];
    page?: number;
    pageSize?: number;
    participationType?: ParticipationType;
    providerExportStatuses?: ExportStatus[];
    searchTerm?: string;
}

export class FreeLegData extends AbstractLegData {
    desaddle?: boolean;
    deselectable?: boolean;
    deselected?: boolean;
    /**
     * @deprecated
     */
    fromAddress?: AddressLocationData;
    multiStops?: number;
    shuntRequired?: boolean;
    specialEquipment?: SpecialEquipment;
    /**
     * @deprecated
     */
    toAddress?: AddressLocationData;
}

export class SurChargeData extends PriceData {
    baseUnit?: SurchargeBaseUnit;
    hidden?: boolean;
    legType?: LegType;
    name?: string;
    ownerId?: UUID;
    ownerType?: SurchargeOwnerType;
    relative?: boolean;
    type?: SurChargeType;
    validFrom?: DateAsString;
    validTo?: DateAsString;
}

export class FreeLegSurchargesEntryData extends SurChargeData {
    pricelist?: SchedulePricelistData;
    terminal?: LocationData;
}

export interface GenericDeclaration extends AnnotatedElement {
    typeParameters?: TypeVariable<any>[];
}

export class GeoLocationData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    lat?: number;
    lon?: number;
    modifiedAt?: DateAsString;
    type?: LocationType;
}

export class GetOffersRequestData extends BasicGetOffersRequestData {
    completed?: boolean;
    completeness?: boolean;
    customerCompanies?: UUID[];
    customerCompanyId?: UUID;
    customerCompanyIds?: UUID[];
    customerUserId?: UUID;
    endTerminalIds?: UUID[];
    excludeStatus?: OfferStatus[];
    hoursSinceCreation?: number;
    hoursUntilClosing?: number;
    legProviderId?: UUID;
    operatorId?: UUID;
    provider?: UUID[];
    providerIds?: UUID[];
    relationTimeTableIds?: UUID[];
    sorts?: any[];
    startTerminalIds?: UUID[];
    status?: OfferStatus[];
    viewGroupIds?: UUID[];
}

export class GetBookingExportRequestData extends GetOffersRequestData {
    exportFormat?: ExportFormat;
    includeDocuments?: boolean;
    legIds?: UUID[];
    legTypes?: LegType[];
    participationType?: ParticipationType;
    updateExportStatus?: boolean;
}

export class GetLegPricelistResponseData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "depotPrices")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => LocationData)
    depotPrices?: LocationData[];
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "prices")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => LegPricelistEntryData)
    prices?: LegPricelistEntryData[];
    @Reflect.metadata("propertyName", "surcharges")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => FreeLegSurchargesEntryData)
    surcharges?: FreeLegSurchargesEntryData[];
    @Reflect.metadata("propertyName", "terminalId")
    terminalId?: UUID;
    @Reflect.metadata("propertyName", "validFrom")
    validFrom?: DateAsString;
    @Reflect.metadata("propertyName", "validTo")
    validTo?: DateAsString;
}

export class GetPricelistEntryData extends CreateOrUpdatePricelistEntryData {
}

export class GetPricelistResponseData implements Bean {
    createdAt?: DateAsString;
    entries?: GetPricelistEntryData[];
    id?: UUID;
    modifiedAt?: DateAsString;
    scheduleId?: UUID;
}

export class ScheduleData implements Bean, Comparable<ScheduleData> {
    archived?: boolean;
    createdAt?: DateAsString;
    hasPricelists?: boolean;
    hasRelationSurcharges?: boolean;
    id?: UUID;
    importStatus?: ImportStatus;
    inProgress?: boolean;
    modifiedAt?: DateAsString;
    name?: string;
    partlyInvalidRelations?: boolean;
    relationSurchargeCreatedAt?: DateAsString;
    relationSurchargeModifiedAt?: DateAsString;
    specialSchedules?: ScheduleData[];
    surchargesApiUpdated?: boolean;
    valid?: boolean;
    validFrom?: DateAsString;
    validTo?: DateAsString;
    year?: string;
}

export class GetScheduleResponseData extends ScheduleData {
    entries?: ScheduleEntryData[];
}

export class HarmonizedCommodityCodeData implements Bean {
    code?: string;
    createdAt?: DateAsString;
    description?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    version?: string;
}

export class HarmonizedCommodityCodeImportData implements Bean {
    code?: string;
    createdAt?: DateAsString;
    description?: { [index: string]: string };
    id?: UUID;
    modifiedAt?: DateAsString;
    version?: number;
}

export class HazardClassificationData implements Bean {
    @Reflect.metadata("propertyName", "code")
    code?: string;
    @Reflect.metadata("propertyName", "createdAt")
    createdAt?: DateAsString;
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "modifiedAt")
    modifiedAt?: DateAsString;
    @Reflect.metadata("propertyName", "name")
    name?: string;
    @Reflect.metadata("propertyName", "visible")
    visible?: boolean;
}

export class HealthStatsData {
    dbstatsHeaders?: string[][];
}

export interface HereMapsResponse {
    routes?: Route[];
}

export class HistoryEntryChangeData implements AbstractDataTransferObject {
    newValue?: any;
    oldValue?: any;
    propertyName?: string;
    propertyType?: string;
}

export class HistoryEntryData implements AbstractDataTransferObject {
    action?: HistoryAction;
    author?: string;
    authorCompany?: string;
    changeType?: SnapshotType;
    changes?: HistoryEntryChangeData[];
    sourceElement?: string;
    sourceId?: any;
    timestamp?: DateAsString;
}

export interface Hourly extends NotificationSchedule {
    endTime?: DateAsString;
    startTime?: DateAsString;
}

export class IdNameData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
}

export class ImpersonateCompanyRequestData implements AbstractDataTransferObject {
    companyId?: UUID;
}

export class SchedulePricelistEntryData implements Bean, Comparable<SchedulePricelistEntryData> {
    createdAt?: DateAsString;
    equipmentType?: EquipmentTypeData;
    id?: UUID;
    kvsRate?: boolean;
    modifiedAt?: DateAsString;
    relation?: SchedulePricelistEntryRelationData;
    values?: SchedulePricelistEntryPriceData[];
}

export class ImportSchedulePricelistEntryData extends SchedulePricelistEntryData {
    scheduleId?: UUID;
}

export class ImportSchedulePricelistRequestData implements Bean {
    batchEntryId?: number;
    batchSize?: number;
    createdAt?: DateAsString;
    customerOrganizationId?: UUID;
    deleteOldData?: boolean;
    entries?: SchedulePricelistEntryData[];
    id?: UUID;
    importTag?: string;
    modifiedAt?: DateAsString;
    ownerCompanyId?: UUID;
    publish?: boolean;
    validity?: Range<DateAsString>;
}

export class ImportSettingsData {
    async?: boolean;
    companyId?: string;
    dryRun?: boolean;
    importFormat?: ImportFormat;
    overwrite?: boolean;
    params?: { [index: string]: any };
}

export class ImportStatusData<T> implements Bean {
    createdAt?: DateAsString;
    data?: T;
    id?: UUID;
    message?: string;
    modifiedAt?: DateAsString;
}

export class ImportStatusSummaryData<S, F> implements Bean {
    createdAt?: DateAsString;
    createdItems?: number;
    deletedItems?: number;
    duplicateItems?: number;
    duplicateItemsDetails?: ImportStatusData<RelationTimetableImportRowData>[];
    failedToImport?: ImportStatusData<F>[];
    id?: UUID;
    modifiedAt?: DateAsString;
    scheduleId?: UUID;
    scheduleName?: string;
    successfullyImported?: ImportStatusData<S>[];
    terminalMatches?: ImportStatusData<{ [index: string]: string }>[];
    totalItemsAfter?: number;
    totalItemsBefore?: number;
    totalItemsFailedImport?: number;
    totalItemsSuccessfullyImported?: number;
    totalProcessedItems?: number;
    updatedItems?: number;
}

export class InboxData {
    contactEmailAddress?: string;
}

export class IntermodalTerminalData {
    ANSPRECHPARTNER_1?: string;
    ANSPRECHPARTNER_MAIL_1?: string;
    BEGASUNG_1?: string;
    BELUEFTUNG_1?: string;
    BETREIBER_1?: string;
    BREMSPROBEANLAGE_1?: string;
    CONTAINERBRUECKE_3?: string;
    CONTAINER_1?: string;
    CONTAINER_WIEGUNG_1?: string;
    DIUM?: string;
    DIUMCOUNTRYCODE?: string;
    FAX_1?: string;
    GEFAHRGUT_1?: string;
    GLEISPLAN?: string;
    GLEISWAAGE_1?: string;
    IS_CONTAINER_DEPOT?: string;
    KOMMISSIONIERUNG_1?: string;
    KRAN_SATTELAUFLIEGER_1?: string;
    LADEGLEISE_3?: string;
    LAENGE_LADEGLEISE_3?: string;
    LAGERUNG_1?: string;
    LAGERUNG_GEFAHRGUT_1?: string;
    LATITUDE?: number;
    LEERDEPOT_1?: string;
    LEERSTAPLER_3?: string;
    LEGENDE?: string;
    LONGITUDE?: number;
    MOBILKRAN_3?: string;
    OCR_1?: string;
    OEFFNUNGSZEITEN_1?: string;
    PORTALKRAN_3?: string;
    REACHSTACKER_3?: string;
    REEFER_1?: string;
    REINIGUNG_LE_1?: string;
    REPARATUR_LE_1?: string;
    ROLA_1?: string;
    RORO_1?: string;
    SATTELAUFLIEGER_1?: string;
    SCHWUNGEINFAHRT_1?: string;
    SERVED_GATEWAY?: string;
    SGKV_CODE?: string;
    SPITZENUEBERSPANNUNG_1?: string;
    STRADDLE_CARRIER_3?: string;
    STUFFING_STRIPPING_1?: string;
    TELEFON_1?: string;
    TERMINAL_BILD_1?: string;
    TERMINAL_BILD_2?: string;
    TERMINAL_BILD_3?: string;
    TERMINAL_BILD_4?: string;
    TERMINAL_BILD_5?: string;
    TERMINAL_LAND?: string;
    TERMINAL_MAIL_1?: string;
    TERMINAL_NAME?: string;
    TERMINAL_ORT?: string;
    TERMINAL_PLZ?: string;
    TERMINAL_STR?: string;
    TERMINAL_STRNR?: string;
    TERMINAL_STR_NR?: string;
    TERMINAL_SUBDIVISION?: string;
    TRAGWAGENREPARATUR_1?: string;
    VERPACKEN_1?: string;
    VNL_DIENSTLEISTER_LOGO_1?: string;
    VNL_DIENSTLEISTER_LOGO_2?: string;
    VNL_DIENSTLEISTER_LOGO_3?: string;
    VNL_DIENSTLEISTER_LOGO_4?: string;
    VNL_DIENSTLEISTER_LOGO_5?: string;
    VNL_DIENSTLEISTER_URL_1?: string;
    VNL_DIENSTLEISTER_URL_2?: string;
    VNL_DIENSTLEISTER_URL_3?: string;
    VNL_DIENSTLEISTER_URL_4?: string;
    VNL_DIENSTLEISTER_URL_5?: string;
    WEBSEITE_1?: string;
    WECHSELBRUECKEN_1?: string;
    ZOLLSTELLE_1?: string;
}

export class InternalCreateOrUpdatePricelistRequestData extends AbstractPricelistRequestData<PricelistEntryData> {
    @Reflect.metadata("propertyName", "entries")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => PricelistEntryData)
    entries?: PricelistEntryData[];
    @Reflect.metadata("propertyName", "new")
    new?: boolean;
    @Reflect.metadata("propertyName", "pricelistId")
    pricelistId?: UUID;
    @Reflect.metadata("propertyName", "publish")
    publish?: boolean;
}

export class InvoiceGenerationRequestData {
    commissionValue?: number;
    contactPerson?: UserData;
    customerId?: string;
    data?: InvoiceLineItemData[];
    debug?: boolean;
    deliveryDate?: string;
    invoiceDate?: string;
    invoiceNumber?: string;
    logoPath?: string;
    paymentDueDate?: string;
    recipient?: UserData;
    relations?: { [index: string]: string };
    totalGross?: number;
    totalNet?: number;
    totalVat?: number;
    vatValue?: number;
}

export class InvoiceLineItemData {
    bookingNumber?: string;
    comission?: number;
    groupId?: string;
    internalReferenceNumber?: string;
    person?: string;
    volumeOfSales?: number;
}

export class LegPricelistData implements Bean {
    createdAt?: DateAsString;
    depotPrices?: LocationData[];
    file?: FileData;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
    owner?: CompanyData;
    prices?: PriceData[];
    surcharges?: SurChargeData[];
    surchargesApiUpdated?: boolean;
    terminal?: TerminalLocationData;
}

export class LegPricelistEntryData implements AbstractDataTransferObject, Comparable<LegPricelistEntryData> {
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "maxDistance")
    maxDistance?: number;
    @Reflect.metadata("propertyName", "value")
    value?: number;
}

export class ListFreeLegSurchargesRequestData implements AbstractDataTransferObject {
    nameFilter?: string;
    page?: number;
    pageSize?: number;
    sortFields?: string[];
    terminalIds?: UUID[];
    types?: SurChargeType[];
}

export class RelationSearchRequestData implements Bean {
    arrivalDateTime?: Range<DateAsString>;
    closingDateTime?: Range<DateAsString>;
    createdAt?: DateAsString;
    end?: AddressLocationRequestData;
    endDepotId?: UUID;
    endTerminalFilter?: UUID[];
    equipmentQuantity?: number;
    equipmentType?: string;
    firstLeg?: SearchRequestLegData;
    hazardClassification?: string;
    id?: UUID;
    lastLeg?: SearchRequestLegData;
    maxWeight?: number;
    modifiedAt?: DateAsString;
    page?: number;
    priceRangeFilter?: Range<number>;
    providerFilter?: UUID[];
    requestedAt?: DateAsString;
    roundTripDepotId?: UUID;
    roundTripReturnLegOffset?: number;
    showOnlyAvailableFilter?: boolean;
    showOnlyKvRateFilter?: boolean;
    sortField?: string;
    start?: AddressLocationRequestData;
    startDepotId?: UUID;
    startTerminalFilter?: UUID[];
    transportDirection?: TransportDirectionType;
    transportType?: SearchTransportType;
}

export class ListRelationRequestData extends RelationSearchRequestData {
    calculateCapacities?: boolean;
    filters?: RelationFilterData[];
    ignoreArrivalDate?: DateAsString;
    ignoreClosingDate?: DateAsString;
    includeTerminalFilters?: boolean;
    isRecurring?: boolean;
    mustHaveOffers?: boolean;
    operatorId?: UUID;
    pageSize?: number;
    recurring?: boolean;
}

export class ListRelationResultData implements Bean {
    createdAt?: DateAsString;
    endTerminals?: TerminalLocationData[];
    id?: UUID;
    modifiedAt?: DateAsString;
    relations?: PagedResult<RelationData>;
    schedules?: ScheduleData[];
    startTerminals?: TerminalLocationData[];
}

export class ListRelationSurchargesEntryData extends SurChargeData {
    endTerminal?: LocationData;
    relationId?: UUID;
    startTerminal?: LocationData;
}

export class ListRelationSurchargesRequestData implements AbstractDataTransferObject {
    endTerminal?: UUID[];
    nameFilter?: string;
    page?: number;
    pageSize?: number;
    scheduleId?: UUID;
    sortFields?: string[];
    startTerminal?: UUID[];
    types?: SurChargeType[];
}

export class LoadingUnitData implements Bean {
    comments?: string[];
    commodityCode?: string;
    costOfGoods?: PriceData;
    createdAt?: DateAsString;
    customsDocumentNumber?: string;
    customsNumberPosition?: string;
    customsProcessNumber?: string;
    customsSeal?: string;
    description?: string;
    hazardClassification?: HazardClassificationData;
    hazardClassificationId?: UUID;
    id?: UUID;
    kind?: string;
    loadingMeter?: number;
    modifiedAt?: DateAsString;
    packingType?: PackingType;
    quantity?: number;
    unNumber?: UnNumberData;
    weight?: number;
}

export interface Locale extends Cloneable, Serializable {
}

export class LocalizedDownloadBookingExportData extends DownloadBookingExportData {
    localizations?: { [index: string]: string };
}

export class LocalizedSurChargeData extends SurChargeData {
    localizations?: { [index: string]: string };
}

export interface Location {
    lat?: number;
    lng?: number;
}

export class LocationApiResponseData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "addresses")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => AddressLocationData)
    addresses?: AddressLocationData[];
    @Reflect.metadata("propertyName", "terminals")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => TerminalSearchResultData)
    terminals?: TerminalSearchResultData[];
}

export interface LocationSearchResult extends PagedResult<LocationData> {
    results?: LocationData[];
}

export interface Location__1 {
    lat?: number;
    lng?: number;
}

export interface Location__2 {
    lat?: number;
    lng?: number;
}

export class MailingListData {
    email_address?: string;
    interests?: { [index: string]: boolean };
    merge_fields?: MailingListMergeFieldsData;
    properties?: { [index: string]: string };
    status?: string;
    tags?: MailingListTags[];
}

export class MailingListMergeFieldsData {
    COMPANY?: string;
    FNAME?: string;
    LNAME?: string;
    TITLE?: MailingListTitle;
    fname?: string;
    lname?: string;
}

export class MainEmptyLegData extends AbstractLegData {
    availability?: Availability;
    relation?: RelationData;
    relationId?: UUID;
    specialRatePrice?: boolean;
    statistics?: RelationStatisticsData;
    utilization?: number;
}

export class MainLegData extends AbstractLegData {
    availability?: Availability;
    relation?: RelationData;
    relationId?: UUID;
    specialRatePrice?: boolean;
    statistics?: RelationStatisticsData;
    utilization?: number;
}

export class MandatoryFieldsData {
    missing?: number;
    missingInPercent?: number;
    total?: number;
}

export class MessageData extends AbstractMessageData {
    alsoSendByEmail?: boolean;
    attachments?: AttachmentData[];
    bcc?: string[];
    cc?: string[];
    dialogId?: string;
    emailSubject?: string;
    emailText?: string;
    priority?: Priority;
    replyTo?: string;
    sendStatus?: MessageSendStatus;
    subType?: MessageSubType;
    type?: MessageType;
}

export class MessageRequestData {
    dialogId?: string;
    inboxIds?: UUID[];
    messageId?: string;
    ownerId?: string;
    page?: number;
    pageSize?: number;
    priority?: Priority;
    readStatus?: MessageReadStatus;
    recipient?: string;
    recipients?: string[];
    references?: string[];
    sendStatus?: MessageSendStatus;
    sender?: string;
    senders?: string[];
    showDetails?: boolean;
    sort?: string;
    /**
     * @deprecated
     */
    subType?: MessageSubType;
    subTypes?: MessageSubType[];
    /**
     * @deprecated
     */
    type?: MessageType;
    types?: MessageType[];
}

export interface NewNotificationQueueMessage extends QueueMessage {
    messageSubType?: MessageSubType;
    messageText?: string;
    messageType?: MessageType;
    recipientUid?: string;
    reference?: string;
    sentToCompanyInbox?: boolean;
}

export class NotificationData implements Bean {
    createdAt?: DateAsString;
    emailAddress?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    schedule?: NotificationSchedule;
}

export interface NotificationSchedule {
}

export class NotificationSettingData implements AbstractDataTransferObject {
    enabled?: boolean;
    name?: NotificationSettingName;
}

export class NotificationSettingsData implements AbstractDataTransferObject {
    settings?: { [P in NotificationSettingsGroup]?: NotificationSettingData[] };
}

export interface OfField<F> extends TypeDescriptor {
    array?: boolean;
    primitive?: boolean;
}

export interface OfferAlternationMessage extends QueueMessage {
    companyIds?: string[];
    currentUserId?: UUID;
    offerId?: UUID;
}

export class OfferCapacityStatisticsData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    occupiedUnits?: number;
    occupiedWeightCapacity?: number;
}

export class OfferChangeRequestData implements Bean {
    comment?: string;
    createdAt?: DateAsString;
    deleteContainerIds?: UUID[];
    equipmentQuantity?: number;
    equipmentType?: EquipmentTypeData;
    firstLeg?: OfferChangeRequestFreeLegData;
    firstLegDepot?: OfferChangeRequestDepotLegData;
    id?: UUID;
    lastLeg?: OfferChangeRequestFreeLegData;
    lastLegDepot?: OfferChangeRequestDepotLegData;
    modifiedAt?: DateAsString;
    offerId?: UUID;
    pricePerEquipment?: number;
    recipient?: CompanyData;
    relation?: RelationData;
    removeFirstLeg?: boolean;
    removeFirstLegDepot?: boolean;
    removeLastLeg?: boolean;
    removeLastLegDepot?: boolean;
    reuseExistingRequestContent?: boolean;
    sender?: CompanyData;
    status?: OfferChangeRequestStatus;
    totalPrice?: number;
    weightClass?: WeightClassData;
}

export class OfferChangeRequestDecisionNotificationData {
    expired?: boolean;
    recipient?: any;
}

export class OfferChangeRequestDepotLegData implements Bean {
    createdAt?: DateAsString;
    depot?: ContainerDepotLocationData;
    id?: UUID;
    modifiedAt?: DateAsString;
    requiredTimeRangeOfArrival?: any;
    requiredTimeRangeOfDeparture?: any;
}

export class OfferChangeRequestFreeLegData implements Bean {
    createdAt?: DateAsString;
    fromAddress?: AddressLocationData;
    id?: UUID;
    modifiedAt?: DateAsString;
    /**
     * @deprecated for removal
     */
    requiredTimeOfArrival?: DateAsString;
    /**
     * @deprecated for removal
     */
    requiredTimeOfDeparture?: DateAsString;
    requiredTimeRangeOfArrival?: any;
    requiredTimeRangeOfDeparture?: any;
    shuntRequired?: boolean;
    toAddress?: AddressLocationData;
}

export class OfferChangeRequestNotificationData {
    author?: string;
    changedPrice?: number;
    comment?: string;
    oldPrice?: number;
}

export class OfferData extends BaseOfferData {
    allowedActions?: AllowedOfferActionsData;
    bookingNumber?: string;
    bookingStatus?: BookingStatus;
    changeRequestAvailable?: boolean;
    comment?: string;
    comments?: OfferMessageData[];
    complete?: boolean;
    completedManually?: boolean;
    completeness?: number;
    customer?: CompanyData;
    customerEmployee?: UserData;
    offerStatus?: OfferStatus;
    operator?: CompanyData;
    priceTariffType?: string;
    requiredUnits?: number;
    requiredWeightCapacity?: number;
    sameChargeDataForAllContainers?: boolean;
    totalDuration?: DurationData;
    totalPrice?: PriceData;
    totalWeight?: number;
    volumeOfSales?: PriceData;
}

export class OfferDocumentData implements Bean {
    containerId?: UUID;
    createdAt?: DateAsString;
    documentType?: OfferDocumentType;
    filename?: string;
    id?: UUID;
    loadingUnitId?: UUID;
    modifiedAt?: DateAsString;
    offerId?: UUID;
    size?: number;
}

export class OfferDocumentUploadResultData implements AbstractDataTransferObject {
    failedDocuments?: { [index: string]: OfferDocumentValidationError };
    uploadedDocuments?: OfferDocumentData[];
}

export class OfferDocumentsData implements Bean {
    createdAt?: DateAsString;
    customsDocuments?: BlobData[];
    dangerousGoodsDocuments?: BlobData[];
    goodsDocuments?: BlobData[];
    id?: UUID;
    modifiedAt?: DateAsString;
}

export class OfferMessageData extends MessageData {
    senderCompanyName?: string;
    senderUserName?: string;
}

export class OfferNotificationData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    subject?: string;
    text?: string;
}

export class OfferPriceData {
    firstLegAddress?: AddressLocationData;
    lastLegAddress?: AddressLocationData;
    surcharges?: SurChargeData[];
    totalPrice?: ExtendedPriceData;
    unitPrice?: ExtendedPriceData;
}

export class OfferSearchFilterData implements AbstractDataTransferObject {
    selectedOptions?: string[];
    type?: OfferSearchFilterType;
}

export class OfferSearchFilterOptionData implements AbstractDataTransferObject {
    availableOptions?: any[];
    type?: OfferSearchFilterType;
}

export class OfferSearchRequestData implements Bean {
    bookingFrom?: DateAsString;
    bookingTo?: DateAsString;
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    page?: number;
    pageSize?: number;
    searchFilter?: OfferSearchFilterData[];
    searchTerm?: string;
    sortField?: string;
}

export interface OfferStatusOverview extends Bean {
    accepted?: number;
    canceled?: number;
    declined?: number;
    inCreation?: number;
    pending?: number;
    unknown?: number;
}

export interface OpenAPI {
}

export class OpeningDayData implements Bean {
    closed?: boolean;
    closing?: string;
    createdAt?: DateAsString;
    date?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    opening?: string;
    weekday?: string;
}

export interface OperatorRelationSearchResultInternal extends Bean {
    co2Saving?: number;
    dayOfClosingConcrete?: DateAsString;
    duration?: Duration;
    entry?: any;
    utilization?: number;
}

export class OrganizationData implements Bean {
    address?: AddressLocationData;
    billingAddress?: AddressLocationData;
    contactEmailAddress?: string;
    createdAt?: DateAsString;
    customerNumber?: string;
    id?: UUID;
    logo?: BlobData;
    logoUrl?: string;
    modifiedAt?: DateAsString;
    name?: string;
    preferredLocale?: Locale;
    sites?: CompanyData[];
    subscription?: BookedSubscriptionData;
    subscriptions?: BookedSubscriptionData[];
}

export interface OriginalLocation {
    lat?: number;
    lng?: number;
}

export interface OriginalLocation__1 {
    lat?: number;
    lng?: number;
}

export interface PagedResult<T> extends Bean {
    page?: number;
    pageSize?: number;
    resultCount?: number;
    results?: T[];
    totalPages?: number;
    type?: string;
}

export class PasswordData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    oldPassword?: string;
    password?: string;
    passwordValidation?: string;
}

export class PasswordTokenData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    token?: string;
    uid?: string;
    used?: boolean;
    validDuration?: number;
}

export class Payload<T> implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "data")
    data?: T;
    @Reflect.metadata("propertyName", "errors")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => Status)
    errors?: Status[];
    @Reflect.metadata("propertyName", "httpStatus")
    @Reflect.metadata("isEnumType", true)
    httpStatus?: HttpStatus;
    @Reflect.metadata("propertyName", "infos")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => Status)
    infos?: Status[];
    @Reflect.metadata("propertyName", "path")
    path?: string;
    @Reflect.metadata("propertyName", "warnings")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => Status)
    warnings?: Status[];
}

export class PaymentInfoData {
    billingAddress?: AddressLocationData;
    creditCard?: CreditCardData;
}

export class PersonData implements Bean {
    createdAt?: DateAsString;
    firstName?: string;
    id?: UUID;
    lastName?: string;
    modifiedAt?: DateAsString;
    title?: Title;
}

export interface Place {
    location?: Location;
    originalLocation?: OriginalLocation;
    type?: string;
}

export interface Place__1 {
    location?: Location__1;
    originalLocation?: OriginalLocation__1;
    type?: string;
}

export interface Price {
    currency?: string;
    type?: string;
    value?: number;
}

export class PriceExportData implements Bean {
    createdAt?: DateAsString;
    endTerminalCity?: string;
    endTerminalCountry?: string;
    endTerminalDium?: string;
    endTerminalId?: string;
    endTerminalName?: string;
    equipmentType?: any;
    id?: UUID;
    modifiedAt?: DateAsString;
    prices?: { [index: string]: number };
    relationId?: string;
    scheduleName?: string;
    startTerminalCity?: string;
    startTerminalCountry?: string;
    startTerminalDium?: string;
    startTerminalId?: string;
    startTerminalName?: string;
    validFrom?: DateAsString;
    validTo?: DateAsString;
}

export class PricelistEntryData extends AbstractPricelistEntryData {
    relationId?: UUID;
}

export class PromotionData implements Bean {
    createdAt?: DateAsString;
    date?: DateAsString;
    discount?: number;
    id?: UUID;
    modifiedAt?: DateAsString;
    relation?: RelationData;
    text?: string;
}

export class PromotionNotificationFromPricesData {
    container?: number;
    containerLink?: string;
    swapbody?: number;
    swapbodyLink?: string;
    trailer?: number;
    trailerLink?: string;
}

export interface QueueMessage extends AbstractDataTransferObject {
    type?: QueueMessageType;
}

export interface Range<T> extends Bean {
    lower?: T;
    upper?: T;
}

export class RegistrationData implements Bean {
    acceptDataProtectionPolicy?: boolean;
    acceptGTC?: boolean;
    bookedSubscription?: BookedSubscriptionData;
    company?: CompanyData;
    contactInfo?: UserData;
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    referrer?: RegistrationReferrer;
}

export class RegistrationResultData implements Bean {
    company?: CompanyData;
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    user?: UserData;
}

export class RegularBusinessContractData implements Bean, Clonable<RegularBusinessContractData> {
    applied?: boolean;
    bookingSeries?: boolean;
    createdAt?: DateAsString;
    dayOfWeek?: DayOfWeek;
    endDate?: DateAsString;
    firstInstance?: DateAsString;
    id?: UUID;
    intervalInWeeks?: number;
    lastInstance?: DateAsString;
    modifiedAt?: DateAsString;
    name?: string;
    offers?: { [index: string]: OfferData };
    pricePerEquipment?: number;
    reference?: string;
    specialRate?: boolean;
}

export class RelationFilterData implements Bean {
    createdAt?: DateAsString;
    criteria?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    type?: RelationFilterType;
}

export class RelationOverviewEntryData implements Bean {
    arrival?: DateAsString;
    availability?: Availability;
    co2saving?: number;
    createdAt?: DateAsString;
    departure?: DateAsString;
    duration?: DurationData;
    endTerminal?: IdNameData;
    id?: UUID;
    modifiedAt?: DateAsString;
    operator?: CompanyData;
    relationId?: UUID;
    startTerminal?: IdNameData;
    transportCarriers?: TransportCarrier[];
}

export class RelationSearchRequestChargeData implements Bean {
    createdAt?: DateAsString;
    equipmentQuantity?: number;
    equipmentType?: EquipmentTypeData;
    hazardClass?: HazardClassificationData;
    id?: UUID;
    maxWeight?: number;
    modifiedAt?: DateAsString;
    weightClass?: WeightClassData;
}

export interface RelationSearchResult extends PagedResult<RelationSearchResultEntryData> {
    allResultCount?: number;
    dateToFarAwayWarning?: boolean;
    endTerminalFilterValues?: LocationData[];
    otherResults?: RelationSearchResultEntryData[];
    priceRangeFilterValues?: Range<number>;
    providerFilterValues?: CompanyData[];
    requestedChargeData?: RelationSearchRequestChargeData;
    requestedEndLocation?: GeoLocationData;
    requestedStartLocation?: GeoLocationData;
    results?: RelationSearchResultEntryData[];
    searchType?: SearchType;
    startTerminalFilterValues?: LocationData[];
    transportType?: SearchTransportType;
}

export class RelationSearchResultEntryData implements Bean {
    arrivalDateTime?: DateAsString;
    availability?: Availability;
    bookableFrom?: DateAsString;
    bookingImpediments?: BookingImpediment[];
    closingDateTime?: DateAsString;
    co2Saving?: number;
    createdAt?: DateAsString;
    departureDateTime?: DateAsString;
    distanceQuotient?: number;
    distanceQuotient2?: number;
    divergingFields?: DivergingField[];
    fallbackDistanceCalculationUsed?: boolean;
    id?: UUID;
    kvOrSpecialRate?: boolean;
    kvRate?: boolean;
    legs?: AbstractLegData[];
    modifiedAt?: DateAsString;
    parent?: RelationSearchResult;
    provider?: CompanyData;
    providerCount?: number;
    providerRating?: CompanyRating;
    specialRate?: boolean;
    totalDistance?: DistanceData;
    totalDuration?: DurationData;
    totalPrice?: PriceData;
    totalStreetDistance?: DistanceData;
    transshipmentCount?: number;
    waypoints?: AddressLocationData[];
}

export interface RelationSearchTrackingData {
    arrival?: string;
    arrivalTerminalId?: UUID;
    date?: DateAsString;
    departure?: string;
    departureTerminalId?: UUID;
    directionType?: TransportDirectionType;
    equipmentCount?: number;
    equipmentType?: string;
    firstLeg?: boolean;
    hazardClassification?: string;
    lastLeg?: boolean;
    roundTripDepotId?: UUID;
    transportType?: SearchTransportType;
    userId?: UUID;
    weightClass?: number;
}

export class RelationStatisticsData implements Bean {
    allOffersComplete?: boolean;
    availableUnits?: number;
    availableWeightCapacity?: number;
    bookedUnits?: number;
    bookedWeightCapacity?: number;
    createdAt?: DateAsString;
    customerCount?: number;
    equipmentUsage?: { [index: string]: number };
    id?: UUID;
    minimumAvailableUnits?: number;
    minimumAvailableWeightCapacity?: number;
    missingUnits?: number;
    missingWeightCapacity?: number;
    modifiedAt?: DateAsString;
    offerCount?: number;
    pendingUnits?: number;
    pendingWeightCapacity?: number;
    totalExternalUnits?: number;
    totalExternalWeightCapacity?: number;
    totalUnits?: number;
    totalWeightCapacity?: number;
}

export class RelationTimetableImportRowData implements Bean {
    arrival?: TerminalData;
    availableExternalSpaces?: number;
    availableExternalWeightCapacity?: number;
    availableSpaces?: number;
    availableWeightCapacity?: number;
    closingTime?: DateAsString;
    codeClosing?: string;
    codePickup?: string;
    createdAt?: DateAsString;
    days?: DayOfWeek[];
    daysToRemove?: DayOfWeek[];
    departure?: TerminalData;
    excludedDangerousGoodsClasses?: string[];
    id?: UUID;
    modifiedAt?: DateAsString;
    pickupTime?: DateAsString;
    profiles?: string[];
    rawRowData?: { [index: string]: string };
    relationCapacityAutoAcceptThreshold?: number;
    trainNumber?: string;
    transportCarriers?: TransportCarrier[];
    transportCarriersAsString?: string;
    validFrom?: DateAsString;
    validTo?: DateAsString;
    year?: string;
}

export class RequestRouteData implements AbstractDataTransferObject {
    calculationType?: CalculationType;
    endPoint?: GeoLocationData;
    startPoint?: GeoLocationData;
}

export class ResetPasswordRequestData extends PasswordData {
    token?: string;
}

export interface Route {
    id?: string;
    sections?: Section[];
}

export class ScheduleEntryData implements Bean {
    arrival?: TerminalData;
    availableExternalSpaces?: number;
    availableExternalWeightCapacity?: number;
    availableSpaces?: number;
    availableWeightCapacity?: number;
    closingTime?: DateAsString;
    codeClosing?: string;
    codePickup?: string;
    createdAt?: DateAsString;
    days?: DayOfWeek[];
    departure?: TerminalData;
    duration?: Duration;
    excludedDangerousGoodsClasses?: string[];
    id?: UUID;
    modifiedAt?: DateAsString;
    pickupTime?: DateAsString;
    profile?: string[];
    rawValues?: { [index: string]: string };
    relationCapacityAutoAcceptThreshold?: number;
    relationId?: string;
    trainNumber?: string;
    /**
     * @deprecated
     */
    transportCarrier?: TransportCarrier;
    transportCarriers?: TransportCarrier[];
    validFrom?: DateAsString;
    validTo?: DateAsString;
    year?: string;
}

export interface ScheduleImportCompleteQueueMessage extends QueueMessage {
    companyId?: UUID;
    scheduleId?: UUID;
    scheduleName?: string;
    userId?: UUID;
}

export class SchedulePricelistData implements Bean {
    allInPrices?: boolean;
    applyToReturnTour?: boolean;
    archived?: boolean;
    basePricelist?: SchedulePricelistData;
    complete?: boolean;
    createdAt?: DateAsString;
    customerOrganizations?: OrganizationData[];
    editor?: SchedulePricelistEditorData;
    endTerminals?: TerminalLocationData[];
    equipmentTypes?: EquipmentTypeData[];
    id?: UUID;
    importStatus?: ImportStatus;
    inProgress?: boolean;
    modifiedAt?: DateAsString;
    name?: string;
    prices?: PagedResult<SchedulePricelistEntryData>;
    scheduleId?: UUID;
    selectedEndTerminal?: LocationData;
    selectedEquipmentType?: EquipmentTypeData;
    selectedStartTerminal?: LocationData;
    startTerminals?: TerminalLocationData[];
    status?: SchedulePricelistPublishedStatus;
    weightClasses?: WeightClassData[];
}

export class SchedulePricelistEditorData implements Bean {
    basicPrice?: PriceData;
    createdAt?: DateAsString;
    equipmentPrices?: SchedulePricelistEditorPriceData[];
    id?: UUID;
    modifiedAt?: DateAsString;
    overlappingPricesWarning?: boolean;
    weightClassPrices?: SchedulePricelistEditorPriceData[];
}

export class SchedulePricelistEditorPriceData implements Bean, Comparable<SchedulePricelistEditorPriceData> {
    absoluteValue?: number;
    createdAt?: DateAsString;
    currencyCode?: string;
    equipmentType?: EquipmentTypeData;
    id?: UUID;
    message?: string;
    modifiedAt?: DateAsString;
    relativeValue?: number;
    relativeValueActive?: boolean;
    weightClass?: WeightClassData;
}

export class SchedulePricelistEntryPriceData implements Bean, Comparable<SchedulePricelistEntryPriceData> {
    createdAt?: DateAsString;
    currencyCode?: string;
    firstLegAddress?: AddressLocationData;
    id?: UUID;
    includedDepots?: { [index: string]: UUID[] };
    lastLegAddress?: AddressLocationData;
    modifiedAt?: DateAsString;
    validity?: Range<DateAsString>;
    value?: number;
    weightClass?: WeightClassData;
}

export class SchedulePricelistEntryRelationData implements Bean, Comparable<SchedulePricelistEntryRelationData> {
    createdAt?: DateAsString;
    endTerminal?: LocationData;
    id?: UUID;
    modifiedAt?: DateAsString;
    startTerminal?: LocationData;
}

export class SchedulePricelistImportResponseData implements Bean {
    createdAt?: DateAsString;
    failed?: number;
    id?: UUID;
    imported?: number;
    incompatibleRelation?: UUID[];
    modifiedAt?: DateAsString;
    noneExistingRelations?: RelationData[];
}

export class SchedulePricelistRequestData implements Bean {
    applyToReturnTour?: boolean;
    createdAt?: DateAsString;
    endTerminal?: LocationData;
    equipmentType?: EquipmentTypeData;
    id?: UUID;
    includeInitialStep?: boolean;
    modifiedAt?: DateAsString;
    page?: number;
    pageSize?: number;
    startTerminal?: LocationData;
}

export class SearchRequestLegData implements Bean {
    containerDepotId?: UUID;
    createdAt?: DateAsString;
    desaddle?: boolean;
    id?: UUID;
    modifiedAt?: DateAsString;
    multiStops?: number;
    specialEquipment?: SpecialEquipment;
}

export interface Section {
    arrival?: Arrival;
    departure?: Departure;
    id?: string;
    polyline?: string;
    spans?: Span[];
    tollSystems?: TollSystem[];
    tolls?: Toll[];
    transport?: Transport;
    travelSummary?: TravelSummary;
    type?: string;
}

export interface SendRelationNotification extends Bean {
    arrivalDateTime?: DateAsString;
    closingDateTime?: DateAsString;
    notification?: OfferNotificationData;
    relationTimetableId?: UUID;
}

export interface Serializable {
}

export class ShuntData extends AbstractLegData {
}

export class SkipOfferRequestData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    offer?: OfferData;
}

export class SortOrderData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
    value?: string;
}

export interface Span {
    length?: number;
    offset?: number;
    tollSystems?: number[];
}

export interface SpecialRateSearchEntryData extends RelationSearchTrackingData {
    businessContractId?: UUID;
    name?: string;
    operator?: string;
    operatorId?: UUID;
    reference?: string;
    specialRate?: number;
}

export class Status {
    @Reflect.metadata("propertyName", "code")
    code?: string;
    @Reflect.metadata("propertyName", "message")
    message?: string;
}

export class SubscriptionData implements AbstractDataTransferObject {
    maxSites?: number;
    maxUsers?: number;
    prices?: { [P in SubscriptionPaymentInterval]?: number };
    rank?: number;
    uid?: string;
}

export class SurchargeExportData implements Bean {
    arrivalTerminal?: string;
    bafSurcharge?: number;
    congestionEndTerminalSurcharge?: number;
    congestionStartTerminalSurcharge?: number;
    createdAt?: DateAsString;
    customSurcharge?: number;
    dangerousGoodsSurcharge?: number;
    departureTerminal?: string;
    eegSurcharge?: number;
    heavyWeightGoodsSurcharge?: number;
    id?: UUID;
    modifiedAt?: DateAsString;
    relationId?: UUID;
    scheduleName?: string;
}

export interface TemporalAmount {
    units?: TemporalUnit[];
}

export interface TemporalUnit {
    dateBased?: boolean;
    duration?: Duration;
    durationEstimated?: boolean;
    timeBased?: boolean;
}

export class TerminalData implements Bean {
    countryIsoCode?: string;
    createdAt?: DateAsString;
    description?: string;
    diumNumber?: string;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
    uirrNumber?: string;
}

export class TerminalLocationData extends AddressLocationData {
    active?: boolean;
    additionalLine?: string;
    description?: string;
    diumNumber?: string;
    isInnerPort?: boolean;
    isRailTerminal?: boolean;
    isRoadTerminal?: boolean;
    isSeaPort?: boolean;
    /**
     * @deprecated
     */
    isSeaTerminal?: boolean;
    mobile?: string;
    phone2?: string;
    phone3?: string;
    sgkvCode?: string;
    uirrNumber?: string;
    url?: string;
}

export class TerminalSearchResultData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "lat")
    lat?: number;
    @Reflect.metadata("propertyName", "lon")
    lon?: number;
    @Reflect.metadata("propertyName", "name")
    name?: string;
}

export class TimeZoneData implements AbstractDataTransferObject {
    abbreviation?: string;
    countryCode?: string;
    dst?: boolean;
    formatted?: DateAsString;
    gmtOffset?: number;
    status?: string;
    timestamp?: number;
    zoneName?: string;
}

export interface Toll {
    countryCode?: string;
    fares?: Fare[];
    tollSystem?: string;
    tollSystemRef?: number;
}

export interface TollCollectionLocation {
    location?: Location__2;
    name?: string;
}

export interface TollSystem {
    id?: number;
    languageCode?: string;
    name?: string;
}

export interface Transport {
    mode?: string;
}

export interface TravelSummary {
    baseDuration?: number;
    duration?: number;
    length?: number;
}

export interface TwiceDaily extends NotificationSchedule {
}

export interface Type {
    typeName?: string;
}

export interface TypeDescriptor {
}

export interface TypeVariable<D> extends Type, AnnotatedElement {
    annotatedBounds?: AnnotatedType[];
    bounds?: Type[];
    genericDeclaration?: D;
    name?: string;
}

export class UnNumberData implements Bean {
    createdAt?: DateAsString;
    description?: string;
    descriptionEditable?: boolean;
    hazardClassificationCode?: string;
    hazardIdentifiers?: string[];
    hazardIdentifiersEditable?: boolean;
    id?: UUID;
    modifiedAt?: DateAsString;
    unNumber?: string;
}

export class UnNumbersResultData implements Bean {
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    results?: UnNumberData[];
    searchedUnNumberExists?: boolean;
}

export class UnactivatedCompaniesData implements Bean {
    createdAt?: DateAsString;
    header?: string[];
    id?: UUID;
    modifiedAt?: DateAsString;
    unactivatedCompanies?: { [index: string]: string }[];
}

export class UpdateLegPriceRequestData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "baseUnit")
    @Reflect.metadata("isEnumType", true)
    baseUnit?: SurchargeBaseUnit;
    @Reflect.metadata("propertyName", "hidden")
    hidden?: boolean;
    @Reflect.metadata("propertyName", "maxDistance")
    maxDistance?: number;
    @Reflect.metadata("propertyName", "name")
    name?: string;
    @Reflect.metadata("propertyName", "priceId")
    priceId?: UUID;
    @Reflect.metadata("propertyName", "pricelistId")
    pricelistId?: UUID;
    @Reflect.metadata("propertyName", "relative")
    relative?: boolean;
    @Reflect.metadata("propertyName", "schedulePricelistId")
    schedulePricelistId?: UUID;
    @Reflect.metadata("propertyName", "type")
    @Reflect.metadata("isEnumType", true)
    type?: LegPriceType;
    @Reflect.metadata("propertyName", "validFrom")
    validFrom?: DateAsString;
    @Reflect.metadata("propertyName", "validTo")
    validTo?: DateAsString;
    @Reflect.metadata("propertyName", "value")
    value?: number;
}

export class UpdateLegPricelistRequestData extends GetLegPricelistResponseData {
}

export class UpdateOfferLegRequestData implements Bean {
    createdAt?: DateAsString;
    forceUpdate?: boolean;
    id?: UUID;
    legs?: AbstractLegData[];
    modifiedAt?: DateAsString;
}

export class UpdateOfferRequestData extends OfferData {
    relationPriceId?: UUID;
}

export class UpdateRelationPriceData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "equipmentTypeId")
    equipmentTypeId?: UUID;
    @Reflect.metadata("propertyName", "value")
    value?: number;
    @Reflect.metadata("propertyName", "weightClassId")
    weightClassId?: UUID;
}

export class UpdateRelationRequestData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "autoAcceptThreshold")
    autoAcceptThreshold?: number;
    @Reflect.metadata("propertyName", "dateOfArrivalConcrete")
    dateOfArrivalConcrete?: DateAsString;
    @Reflect.metadata("propertyName", "dateOfClosingConcrete")
    dateOfClosingConcrete?: DateAsString;
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "originalArrivalDateTime")
    originalArrivalDateTime?: DateAsString;
    @Reflect.metadata("propertyName", "originalClosingDateTime")
    originalClosingDateTime?: DateAsString;
    @Reflect.metadata("propertyName", "priceMultiplier")
    priceMultiplier?: number;
    @Reflect.metadata("propertyName", "prices")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => UpdateRelationPriceData)
    prices?: UpdateRelationPriceData[];
    @Reflect.metadata("propertyName", "timeOfArrival")
    timeOfArrival?: DateAsString;
    @Reflect.metadata("propertyName", "timeOfClosing")
    timeOfClosing?: DateAsString;
    @Reflect.metadata("propertyName", "totalExternalUnits")
    totalExternalUnits?: number;
    @Reflect.metadata("propertyName", "totalExternalWeightCapacity")
    totalExternalWeightCapacity?: number;
    @Reflect.metadata("propertyName", "totalUnits")
    totalUnits?: number;
    @Reflect.metadata("propertyName", "totalWeightCapacity")
    totalWeightCapacity?: number;
}

export class UpdateRelationResponseData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
}

export class WeightClassData implements Bean, Comparable<WeightClassData> {
    @Reflect.metadata("propertyName", "code")
    code?: string;
    @Reflect.metadata("propertyName", "createdAt")
    createdAt?: DateAsString;
    @Reflect.metadata("propertyName", "customized")
    customized?: boolean;
    @Reflect.metadata("propertyName", "id")
    id?: UUID;
    @Reflect.metadata("propertyName", "maxWeight")
    maxWeight?: number;
    @Reflect.metadata("propertyName", "modifiedAt")
    modifiedAt?: DateAsString;
    @Reflect.metadata("propertyName", "name")
    name?: string;
    @Reflect.metadata("propertyName", "owner")
    owner?: UUID;
}

export class UpdateWeightClassRequestData extends WeightClassData {
    @Reflect.metadata("propertyName", "pricelistId")
    pricelistId?: UUID;
}

export class UploadRelationSurchargeEntryData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "arrivalTerminalId")
    arrivalTerminalId?: UUID;
    @Reflect.metadata("propertyName", "departureTerminalId")
    departureTerminalId?: UUID;
    @Reflect.metadata("propertyName", "relationId")
    relationId?: UUID;
    @Reflect.metadata("propertyName", "surcharges")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => SurChargeData)
    surcharges?: SurChargeData[];
}

export class UploadRelationSurchargeRequestData implements AbstractDataTransferObject {
    @Reflect.metadata("propertyName", "entries")
    @Reflect.metadata("isArray", true)
    @Reflect.metadata("propertyArrayType", () => UploadRelationSurchargeEntryData)
    entries?: UploadRelationSurchargeEntryData[];
    @Reflect.metadata("propertyName", "scheduleId")
    scheduleId?: UUID;
}

export class UserData implements Bean {
    acceptDataProtectionPolicy?: boolean;
    acceptGTC?: boolean;
    acceptedDataProtectionPolicy?: DateAsString;
    acceptedGTC?: DateAsString;
    active?: boolean;
    address?: AddressLocationData;
    company?: CompanyData;
    contactEmailAddress?: string;
    createdAt?: DateAsString;
    deleted?: boolean;
    firstName?: string;
    hasAcceptedDataProtectionPolicy?: boolean;
    hasAcceptedGTC?: boolean;
    id?: UUID;
    lastName?: string;
    message?: string;
    modifiedAt?: DateAsString;
    password?: PasswordData;
    preferredLocale?: Locale;
    profilePicture?: BlobData;
    profilePictureUrl?: string;
    reinvite?: boolean;
    roles?: UserRole[];
    title?: Title;
    uid?: string;
    useSimpleSearch?: boolean;
    viewGroups?: ViewGroupData[];
}

export interface UserDataPayloadResponse extends Payload<UserData> {
    data?: UserData;
}

export interface UserDeletedQueueMessage extends QueueMessage {
    userId?: UUID;
    userUid?: string;
}

export class ValidateCredentialsData implements Bean {
    companyName?: string;
    createdAt?: DateAsString;
    id?: UUID;
    modifiedAt?: DateAsString;
    username?: string;
}

export class VatValidationData {
    isUnique?: boolean;
    isValid?: boolean;
}

export class VatValidationResultData implements Bean {
    address?: string;
    alreadyRegistered?: boolean;
    country?: string;
    countryCode?: string;
    createdAt?: DateAsString;
    externalValidated?: boolean;
    id?: UUID;
    modifiedAt?: DateAsString;
    name?: string;
    requestDate?: string;
    requestTime?: string;
    status?: string;
    validBoolean?: boolean;
    vatNumber?: string;
}

export class WaypointInfoData implements Bean {
    address?: AddressLocationData;
    createdAt?: DateAsString;
    equipmentNumber?: string;
    handoverTime?: DateAsString;
    id?: UUID;
    leg?: AbstractLegData;
    modifiedAt?: DateAsString;
    pin?: string;
}

export type DateAsString = string;

export type RestResponse<R> = Promise<Response<R>>;

export enum Availability {
    Low = "Low",
    High = "High",
}

export enum BookingImpediment {
    EQUIPMENT_TYPE_NOT_SUPPORTED = "EQUIPMENT_TYPE_NOT_SUPPORTED",
    DANGEROUS_GOODS_CLASS_NOT_SUPPORTED = "DANGEROUS_GOODS_CLASS_NOT_SUPPORTED",
    TIME_SLOT_FOR_BOOKINGS_NOT_REACHED_YET = "TIME_SLOT_FOR_BOOKINGS_NOT_REACHED_YET",
    TIME_SLOT_FOR_BOOKINGS_EXCEEDED = "TIME_SLOT_FOR_BOOKINGS_EXCEEDED",
    NO_GENERAL_TERMS_OF_PROVIDER = "NO_GENERAL_TERMS_OF_PROVIDER",
}

export enum BookingNotificationRequestAction {
    Accept = "Accept",
    AcceptDirect = "AcceptDirect",
    Cancel = "Cancel",
    Decline = "Decline",
}

export enum BookingPriceTariffType {
    UNKNOWN = "UNKNOWN",
    DEFAULT = "DEFAULT",
    INDIVIDUAL_DEFAULT = "INDIVIDUAL_DEFAULT",
    INTEGRATED = "INTEGRATED",
    INDIVIDUAL_INTEGRATED = "INDIVIDUAL_INTEGRATED",
    SPECIAL = "SPECIAL",
}

export enum BookingStatus {
    Pending = "Pending",
    Declined = "Declined",
    Canceled = "Canceled",
    RefinementPending = "RefinementPending",
    TransportPending = "TransportPending",
    TransportOngoing = "TransportOngoing",
    Expired = "Expired",
    Completed = "Completed",
    Unknown = "Unknown",
    Planned = "Planned",
    Skipped = "Skipped",
}

export enum CalculationType {
    Direct = "Direct",
    Road = "Road",
    Rail = "Rail",
}

export enum CancellationType {
    Canceled = "Canceled",
    Declined = "Declined",
    Undefined = "Undefined",
}

export enum CaseFormat {
    LOWER_HYPHEN = "LOWER_HYPHEN",
    LOWER_UNDERSCORE = "LOWER_UNDERSCORE",
    LOWER_CAMEL = "LOWER_CAMEL",
    UPPER_CAMEL = "UPPER_CAMEL",
    UPPER_UNDERSCORE = "UPPER_UNDERSCORE",
}

export enum CompanyRating {
    None = "None",
    Premium = "Premium",
}

export enum CompanyReferenceType {
    Allowed = "Allowed",
    Blocked = "Blocked",
}

export enum CompanyType {
    Operator = "Operator",
    Loader = "Loader",
    Carrier = "Carrier",
    Trucker = "Trucker",
    EquipmentOwner = "EquipmentOwner",
    Administration = "Administration",
}

export enum CustomsNumberType {
    NONE = "NONE",
    MRN = "MRN",
    ATB = "ATB",
    ATC = "ATC",
}

export enum CustomsProcess {
    T1 = "T1",
    None = "None",
    Different = "Different",
    CommunityGoods = "CommunityGoods",
}

export enum DayOfWeek {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY",
}

export enum DivergingField {
    DepartureAddress = "DepartureAddress",
    DepartureTerminal = "DepartureTerminal",
    DepartureTime = "DepartureTime",
    DepartureDate = "DepartureDate",
    ArrivalAddress = "ArrivalAddress",
    ArrivalTerminal = "ArrivalTerminal",
    ArrivalTime = "ArrivalTime",
    ArrivalDate = "ArrivalDate",
    SearchType = "SearchType",
}

export enum EquipmentConfigurationType {
    Standard = "Standard",
    SideDoor = "SideDoor",
    DoubleDoor = "DoubleDoor",
    OpenTop = "OpenTop",
    HardTop = "HardTop",
    Platform = "Platform",
    FlatRack = "FlatRack",
    Reefer = "Reefer",
    Ventilated = "Ventilated",
    Tank = "Tank",
    Isolated = "Isolated",
    Bulk = "Bulk",
    Cranable = "Cranable",
    NotCranable = "NotCranable",
    C715 = "C715",
    C745 = "C745",
    C765 = "C765",
    C782 = "C782",
    A1219 = "A1219",
    A1250 = "A1250",
    A1360 = "A1360",
}

export enum ExportFormat {
    EXCEL = "EXCEL",
    JSON = "JSON",
    CSV = "CSV",
    PDF = "PDF",
}

export enum ExportStatus {
    EXPORTED = "EXPORTED",
    NOT_EXPORTED = "NOT_EXPORTED",
    MODIFIED_SINCE_LAST_EXPORT = "MODIFIED_SINCE_LAST_EXPORT",
}

export enum Format {
    json = "json",
    tabular = "tabular",
}

export enum GTCStatus {
    DISABLED = "DISABLED",
    ACCEPTED = "ACCEPTED",
    NOT_ACCEPTED = "NOT_ACCEPTED",
}

export enum Gender {
    Male = "Male",
    Female = "Female",
    Undefined = "Undefined",
}

export enum HistoryAction {
    Created = "Created",
    Updated = "Updated",
    Deleted = "Deleted",
}

/**
 * Values:
 * - `CONTINUE`
 * - `SWITCHING_PROTOCOLS`
 * - `PROCESSING`
 * - `CHECKPOINT`
 * - `OK`
 * - `CREATED`
 * - `ACCEPTED`
 * - `NON_AUTHORITATIVE_INFORMATION`
 * - `NO_CONTENT`
 * - `RESET_CONTENT`
 * - `PARTIAL_CONTENT`
 * - `MULTI_STATUS`
 * - `ALREADY_REPORTED`
 * - `IM_USED`
 * - `MULTIPLE_CHOICES`
 * - `MOVED_PERMANENTLY`
 * - `FOUND`
 * - `MOVED_TEMPORARILY` - @deprecated
 * - `SEE_OTHER`
 * - `NOT_MODIFIED`
 * - `USE_PROXY` - @deprecated
 * - `TEMPORARY_REDIRECT`
 * - `PERMANENT_REDIRECT`
 * - `BAD_REQUEST`
 * - `UNAUTHORIZED`
 * - `PAYMENT_REQUIRED`
 * - `FORBIDDEN`
 * - `NOT_FOUND`
 * - `METHOD_NOT_ALLOWED`
 * - `NOT_ACCEPTABLE`
 * - `PROXY_AUTHENTICATION_REQUIRED`
 * - `REQUEST_TIMEOUT`
 * - `CONFLICT`
 * - `GONE`
 * - `LENGTH_REQUIRED`
 * - `PRECONDITION_FAILED`
 * - `PAYLOAD_TOO_LARGE`
 * - `REQUEST_ENTITY_TOO_LARGE` - @deprecated
 * - `URI_TOO_LONG`
 * - `REQUEST_URI_TOO_LONG` - @deprecated
 * - `UNSUPPORTED_MEDIA_TYPE`
 * - `REQUESTED_RANGE_NOT_SATISFIABLE`
 * - `EXPECTATION_FAILED`
 * - `I_AM_A_TEAPOT`
 * - `INSUFFICIENT_SPACE_ON_RESOURCE` - @deprecated
 * - `METHOD_FAILURE` - @deprecated
 * - `DESTINATION_LOCKED` - @deprecated
 * - `UNPROCESSABLE_ENTITY`
 * - `LOCKED`
 * - `FAILED_DEPENDENCY`
 * - `TOO_EARLY`
 * - `UPGRADE_REQUIRED`
 * - `PRECONDITION_REQUIRED`
 * - `TOO_MANY_REQUESTS`
 * - `REQUEST_HEADER_FIELDS_TOO_LARGE`
 * - `UNAVAILABLE_FOR_LEGAL_REASONS`
 * - `INTERNAL_SERVER_ERROR`
 * - `NOT_IMPLEMENTED`
 * - `BAD_GATEWAY`
 * - `SERVICE_UNAVAILABLE`
 * - `GATEWAY_TIMEOUT`
 * - `HTTP_VERSION_NOT_SUPPORTED`
 * - `VARIANT_ALSO_NEGOTIATES`
 * - `INSUFFICIENT_STORAGE`
 * - `LOOP_DETECTED`
 * - `BANDWIDTH_LIMIT_EXCEEDED`
 * - `NOT_EXTENDED`
 * - `NETWORK_AUTHENTICATION_REQUIRED`
 */
export enum HttpStatus {
    CONTINUE = "CONTINUE",
    SWITCHING_PROTOCOLS = "SWITCHING_PROTOCOLS",
    PROCESSING = "PROCESSING",
    CHECKPOINT = "CHECKPOINT",
    OK = "OK",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    NON_AUTHORITATIVE_INFORMATION = "NON_AUTHORITATIVE_INFORMATION",
    NO_CONTENT = "NO_CONTENT",
    RESET_CONTENT = "RESET_CONTENT",
    PARTIAL_CONTENT = "PARTIAL_CONTENT",
    MULTI_STATUS = "MULTI_STATUS",
    ALREADY_REPORTED = "ALREADY_REPORTED",
    IM_USED = "IM_USED",
    MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
    MOVED_PERMANENTLY = "MOVED_PERMANENTLY",
    FOUND = "FOUND",
    /**
     * @deprecated
     */
    MOVED_TEMPORARILY = "MOVED_TEMPORARILY",
    SEE_OTHER = "SEE_OTHER",
    NOT_MODIFIED = "NOT_MODIFIED",
    /**
     * @deprecated
     */
    USE_PROXY = "USE_PROXY",
    TEMPORARY_REDIRECT = "TEMPORARY_REDIRECT",
    PERMANENT_REDIRECT = "PERMANENT_REDIRECT",
    BAD_REQUEST = "BAD_REQUEST",
    UNAUTHORIZED = "UNAUTHORIZED",
    PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
    NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
    PROXY_AUTHENTICATION_REQUIRED = "PROXY_AUTHENTICATION_REQUIRED",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    CONFLICT = "CONFLICT",
    GONE = "GONE",
    LENGTH_REQUIRED = "LENGTH_REQUIRED",
    PRECONDITION_FAILED = "PRECONDITION_FAILED",
    PAYLOAD_TOO_LARGE = "PAYLOAD_TOO_LARGE",
    /**
     * @deprecated
     */
    REQUEST_ENTITY_TOO_LARGE = "REQUEST_ENTITY_TOO_LARGE",
    URI_TOO_LONG = "URI_TOO_LONG",
    /**
     * @deprecated
     */
    REQUEST_URI_TOO_LONG = "REQUEST_URI_TOO_LONG",
    UNSUPPORTED_MEDIA_TYPE = "UNSUPPORTED_MEDIA_TYPE",
    REQUESTED_RANGE_NOT_SATISFIABLE = "REQUESTED_RANGE_NOT_SATISFIABLE",
    EXPECTATION_FAILED = "EXPECTATION_FAILED",
    I_AM_A_TEAPOT = "I_AM_A_TEAPOT",
    /**
     * @deprecated
     */
    INSUFFICIENT_SPACE_ON_RESOURCE = "INSUFFICIENT_SPACE_ON_RESOURCE",
    /**
     * @deprecated
     */
    METHOD_FAILURE = "METHOD_FAILURE",
    /**
     * @deprecated
     */
    DESTINATION_LOCKED = "DESTINATION_LOCKED",
    UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
    LOCKED = "LOCKED",
    FAILED_DEPENDENCY = "FAILED_DEPENDENCY",
    TOO_EARLY = "TOO_EARLY",
    UPGRADE_REQUIRED = "UPGRADE_REQUIRED",
    PRECONDITION_REQUIRED = "PRECONDITION_REQUIRED",
    TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
    REQUEST_HEADER_FIELDS_TOO_LARGE = "REQUEST_HEADER_FIELDS_TOO_LARGE",
    UNAVAILABLE_FOR_LEGAL_REASONS = "UNAVAILABLE_FOR_LEGAL_REASONS",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
    BAD_GATEWAY = "BAD_GATEWAY",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    GATEWAY_TIMEOUT = "GATEWAY_TIMEOUT",
    HTTP_VERSION_NOT_SUPPORTED = "HTTP_VERSION_NOT_SUPPORTED",
    VARIANT_ALSO_NEGOTIATES = "VARIANT_ALSO_NEGOTIATES",
    INSUFFICIENT_STORAGE = "INSUFFICIENT_STORAGE",
    LOOP_DETECTED = "LOOP_DETECTED",
    BANDWIDTH_LIMIT_EXCEEDED = "BANDWIDTH_LIMIT_EXCEEDED",
    NOT_EXTENDED = "NOT_EXTENDED",
    NETWORK_AUTHENTICATION_REQUIRED = "NETWORK_AUTHENTICATION_REQUIRED",
}

export enum ImportDataType {
    initial = "initial",
    project = "project",
    sample = "sample",
    test = "test",
}

export enum ImportFormat {
    HUPAC = "HUPAC",
    SBB = "SBB",
}

export enum ImportStatus {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
}

/**
 * Values:
 * - `Invoice`
 * - `CancellationFee` - @deprecated
 */
export enum InvoiceEntryType {
    Invoice = "Invoice",
    /**
     * @deprecated
     */
    CancellationFee = "CancellationFee",
}

export enum InvoiceType {
    Regular = "Regular",
}

export enum LegPriceType {
    LEG_PRICE = "LEG_PRICE",
    DEPOT_PRICE = "DEPOT_PRICE",
    SURCHARGE = "SURCHARGE",
}

export enum LegType {
    FIRST = "FIRST",
    MAIN = "MAIN",
    MAIN_EMPTY = "MAIN_EMPTY",
    LAST = "LAST",
    SHUNT = "SHUNT",
    DEPOT_DROPOFF = "DEPOT_DROPOFF",
    DEPOT_PICKUP = "DEPOT_PICKUP",
}

export enum LocationType {
    Coordinates = "Coordinates",
    Terminal = "Terminal",
    Address = "Address",
    TerminalContainerDepot = "TerminalContainerDepot",
    ContainerDepot = "ContainerDepot",
    CompanyPOI = "CompanyPOI",
}

export enum LogLevel {
    DEBUG = "DEBUG",
    INFO = "INFO",
    WARN = "WARN",
    ERROR = "ERROR",
    TRACE = "TRACE",
}

export enum MailingListTags {
    Operator = "Operator",
    Spediteur = "Spediteur",
    Deutsch = "Deutsch",
    Englisch = "Englisch",
    Registriert = "Registriert",
    Eingeladen = "Eingeladen",
}

export enum MailingListTitle {
    Herr = "Herr",
    Frau = "Frau",
}

export enum MainLegDocumentsType {
    Customs = "Customs",
    Goods = "Goods",
    DangerousGoods = "DangerousGoods",
}

export enum MessageReadStatus {
    UNREAD = "UNREAD",
    READ = "READ",
    NEW = "NEW",
}

export enum MessageSendStatus {
    UNSENT = "UNSENT",
    SENT = "SENT",
}

export enum MessageSubType {
    ACTIVITY_ACCEPT_OFFER = "ACTIVITY_ACCEPT_OFFER",
    ACTIVITY_COMPLETE_OFFER = "ACTIVITY_COMPLETE_OFFER",
    ACTIVITY_REMIND_COMPLETE_OFFER = "ACTIVITY_REMIND_COMPLETE_OFFER",
    COLLABORATION = "COLLABORATION",
    OFFER_CHAT_MESSAGE = "OFFER_CHAT_MESSAGE",
    TIME_UPDATE = "TIME_UPDATE",
    FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST",
    SET_PASSWORD = "SET_PASSWORD",
    ACTIVITY_ACCEPT_LEG = "ACTIVITY_ACCEPT_LEG",
    REGISTRATION_CONFIRMATION = "REGISTRATION_CONFIRMATION",
    LEG_ARCHIVED = "LEG_ARCHIVED",
    NEW_OFFER = "NEW_OFFER",
    ACTIVITY_REMIND_ACCEPT_OFFER = "ACTIVITY_REMIND_ACCEPT_OFFER",
    NEW_INVOICE = "NEW_INVOICE",
    NEW_CHANGE_REQUEST = "NEW_CHANGE_REQUEST",
    CHANGE_REQUEST_REMINDER = "CHANGE_REQUEST_REMINDER",
    CHANGE_REQUEST_PRICE_ADAPTED = "CHANGE_REQUEST_PRICE_ADAPTED",
    SCHEDULE_IMPORT_FINISHED = "SCHEDULE_IMPORT_FINISHED",
    PRICELIST_IMPORT_FINISHED = "PRICELIST_IMPORT_FINISHED",
    OFFER_ACCEPTED = "OFFER_ACCEPTED",
    NEW_PROMOTION = "NEW_PROMOTION",
    CHANGE_REQUEST_ACCEPTED = "CHANGE_REQUEST_ACCEPTED",
    CHANGE_REQUEST_DECLINED = "CHANGE_REQUEST_DECLINED",
    REMIND_SKIP_BOOKING_SERIES_ENTRY = "REMIND_SKIP_BOOKING_SERIES_ENTRY",
    BOOKING_SERIES_NEW_PRICE = "BOOKING_SERIES_NEW_PRICE",
    BOOKING_SERIES_NEW_PRICE_ACCEPTED = "BOOKING_SERIES_NEW_PRICE_ACCEPTED",
    BOOKING_SERIES_NEW_PRICE_DECLINED = "BOOKING_SERIES_NEW_PRICE_DECLINED",
    SUBSCRIPTION_UPGRADE = "SUBSCRIPTION_UPGRADE",
    SUBSCRIPTION_TRIAL_ENDING_SOON = "SUBSCRIPTION_TRIAL_ENDING_SOON",
    SUBSCRIPTION_CANCELED = "SUBSCRIPTION_CANCELED",
    SUBSCRIPTION_CANCELED_SOON = "SUBSCRIPTION_CANCELED_SOON",
    SUBSCRIPTION_PURCHASED = "SUBSCRIPTION_PURCHASED",
}

export enum MessageType {
    INFO = "INFO",
    ACTIVITY = "ACTIVITY",
    WARNING = "WARNING",
    FEEDBACK_REQUEST = "FEEDBACK_REQUEST",
    MAIL_ONLY = "MAIL_ONLY",
}

export enum ModeOfTransportation {
    CONTINENTAL = "CONTINENTAL",
    MARITIME_IMPORT = "MARITIME_IMPORT",
    MARITIME_EXPORT = "MARITIME_EXPORT",
}

export enum NotificationSettingName {
    New = "New",
    Updated = "Updated",
    Cancelled = "Cancelled",
    Todo = "Todo",
    NewComment = "NewComment",
    Summary = "Summary",
    ChangeRequest = "ChangeRequest",
    BookingSeries = "BookingSeries",
}

export enum NotificationSettingsGroup {
    Booking = "Booking",
    IndividualPricelist = "IndividualPricelist",
    DailySummary = "DailySummary",
    Promotion = "Promotion",
}

/**
 * Values:
 * - `InformationIncomplete`
 * - `CapacityExceeded`
 * - `CannotOrganizeFreeLeg`
 * - `BookingCameTooLate`
 * - `PartialOutage`
 * - `ProductDelay`
 * - `FreeLegProviderMissing`
 * - `TransportConditionsChanged`
 * - `Custom`
 * - `TechnicalProblems` - @deprecated
 * - `Unknown`
 * - `ProductionProblems`
 * - `NotEnoughBookings`
 * - `NoPriceAgreement`
 * - `InvalidReference`
 * - `CannotOrganizeTransport`
 * - `OperatorDeletedSchedule`
 * - `OperatorDeletedSpecialSchedule`
 */
export enum OfferCancellationReason {
    InformationIncomplete = "InformationIncomplete",
    CapacityExceeded = "CapacityExceeded",
    CannotOrganizeFreeLeg = "CannotOrganizeFreeLeg",
    BookingCameTooLate = "BookingCameTooLate",
    PartialOutage = "PartialOutage",
    ProductDelay = "ProductDelay",
    FreeLegProviderMissing = "FreeLegProviderMissing",
    TransportConditionsChanged = "TransportConditionsChanged",
    Custom = "Custom",
    /**
     * @deprecated
     */
    TechnicalProblems = "TechnicalProblems",
    Unknown = "Unknown",
    ProductionProblems = "ProductionProblems",
    NotEnoughBookings = "NotEnoughBookings",
    NoPriceAgreement = "NoPriceAgreement",
    InvalidReference = "InvalidReference",
    CannotOrganizeTransport = "CannotOrganizeTransport",
    OperatorDeletedSchedule = "OperatorDeletedSchedule",
    OperatorDeletedSpecialSchedule = "OperatorDeletedSpecialSchedule",
}

export enum OfferChangeRequestStatus {
    Requested = "Requested",
    Accepted = "Accepted",
    Declined = "Declined",
}

export enum OfferDocumentType {
    Customs = "Customs",
    Goods = "Goods",
    HazardGoods = "HazardGoods",
    General = "General",
}

export enum OfferDocumentValidationError {
    UnsupportedFileType = "UnsupportedFileType",
    InvalidName = "InvalidName",
    VirusFound = "VirusFound",
    FileToBig = "FileToBig",
    Internal = "Internal",
}

export enum OfferFilter {
    TransportPending = "TransportPending",
    TransportOngoing = "TransportOngoing",
    TransportCompleted = "TransportCompleted",
    Complete = "Complete",
    Incomplete = "Incomplete",
    Pending = "Pending",
    Accepted = "Accepted",
    Declined = "Declined",
    Canceled = "Canceled",
}

export enum OfferSearchFilterType {
    OfferStatus = "OfferStatus",
    StartTerminal = "StartTerminal",
    EndTerminal = "EndTerminal",
    Completeness = "Completeness",
    CustomerCompany = "CustomerCompany",
    Provider = "Provider",
    ViewGroup = "ViewGroup",
}

export enum OfferStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    Declined = "Declined",
    Canceled = "Canceled",
    Unknown = "Unknown",
    InCreation = "InCreation",
    Expired = "Expired",
    Finished = "Finished",
    Planned = "Planned",
    Skipped = "Skipped",
}

export enum PackingType {
    Pallet = "Pallet",
    Cardboard = "Cardboard",
}

export enum ParticipationType {
    CLIENT = "CLIENT",
    CONTRACTOR = "CONTRACTOR",
}

export enum PriceType {
    LegPrice = "LegPrice",
    RelationPrice = "RelationPrice",
    SurCharge = "SurCharge",
}

export enum Priority {
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
}

export enum QueueMessageType {
    ScheduleImportComplete = "ScheduleImportComplete",
    NewNotification = "NewNotification",
    FeatureFlagToggled = "FeatureFlagToggled",
    UserDeleted = "UserDeleted",
    OfferContentUpdated = "OfferContentUpdated",
    OfferStatusChanged = "OfferStatusChanged",
    OfferChangeRequestAction = "OfferChangeRequestAction",
    Unknown = "Unknown",
}

export enum RegistrationReferrer {
    LinkedIn = "LinkedIn",
    Instagram = "Instagram",
    Google = "Google",
    Event = "Event",
    Press = "Press",
    Recommendation = "Recommendation",
    Advertisement = "Advertisement",
    Other = "Other",
}

export enum RelationFilterType {
    OfferStatus = "OfferStatus",
    StartTerminal = "StartTerminal",
    EndTerminal = "EndTerminal",
    Schedule = "Schedule",
}

export enum ResponseReturnType {
    actions = "actions",
    elevation = "elevation",
    incidents = "incidents",
    instructions = "instructions",
    mlDuration = "mlDuration",
    passthrough = "passthrough",
    polyline = "polyline",
    routeHandle = "routeHandle",
    routingZones = "routingZones",
    summary = "summary",
    tolls = "tolls",
    travelSummary = "travelSummary",
    truckRoadTypes = "truckRoadTypes",
    turnByTurnActions = "turnByTurnActions",
    typicalDuration = "typicalDuration",
}

export enum SchedulePricelistPublishedStatus {
    DRAFT = "DRAFT",
    UNPUBLISHED_CHANGES = "UNPUBLISHED_CHANGES",
    PUBLISHED = "PUBLISHED",
}

export enum ScheduleType {
    MAIN = "MAIN",
    HOLIDAY = "HOLIDAY",
    CONSTRUCTION = "CONSTRUCTION",
}

export enum SearchMode {
    Departure = "Departure",
    Arrival = "Arrival",
}

export enum SearchTransportType {
    CONTINENTAL = "CONTINENTAL",
    MARITIME_IMPORT = "MARITIME_IMPORT",
    MARITIME_EXPORT = "MARITIME_EXPORT",
}

export enum SearchType {
    House2House = "House2House",
    House2Terminal = "House2Terminal",
    Terminal2House = "Terminal2House",
    Terminal2Terminal = "Terminal2Terminal",
    Unknown = "Unknown",
}

export enum SectionSpan {
    length = "length",
    tollSystems = "tollSystems",
}

export enum SnapshotType {
    INITIAL = "INITIAL",
    UPDATE = "UPDATE",
    TERMINAL = "TERMINAL",
}

export enum SortOrderType {
    LOCATION_SEARCH = "LOCATION_SEARCH",
    RELATION_SEARCH = "RELATION_SEARCH",
    RELATIONS_OPERATOR_DASHBOARD = "RELATIONS_OPERATOR_DASHBOARD",
    USERS_LIST = "USERS_LIST",
    OFFER_LIST = "OFFER_LIST",
    LEG_LIST = "LEG_LIST",
    POINTOFINTEREST_LIST = "POINTOFINTEREST_LIST",
    EQUIPMENT_TYPE = "EQUIPMENT_TYPE",
    WEIGHT_CLASS = "WEIGHT_CLASS",
    HAZARD_CLASS = "HAZARD_CLASS",
    COMPANY_LIST = "COMPANY_LIST",
    RELATION_SURCHARGE = "RELATION_SURCHARGE",
    FREELEG_SURCHARGE = "FREELEG_SURCHARGE",
    BOOKING_FAVORITE = "BOOKING_FAVORITE",
    ORGANIZATION_LIST = "ORGANIZATION_LIST",
}

export enum SpecialEquipment {
    TippingChassis = "TippingChassis",
    SideLoader = "SideLoader",
}

export enum SubscriptionPaymentInterval {
    Monthly = "Monthly",
    Yearly = "Yearly",
}

export enum SurChargeType {
    HeavyLiftAdditional = "HeavyLiftAdditional",
    OutageFee = "OutageFee",
    RiskPremium = "RiskPremium",
    ParkingFee = "ParkingFee",
    CustomsFee = "CustomsFee",
    HazardFee = "HazardFee",
    LongLengthAdditional = "LongLengthAdditional",
    SmallVolumePurchaseMarkup = "SmallVolumePurchaseMarkup",
    EEG = "EEG",
    BAF = "BAF",
    CongestionStartTerminal = "CongestionStartTerminal",
    CongestionEndTerminal = "CongestionEndTerminal",
    FreeLegHazardFee = "FreeLegHazardFee",
    FreeLegContainer45Fee = "FreeLegContainer45Fee",
    FreeLegDesaddle = "FreeLegDesaddle",
    FreeLegMultiStop = "FreeLegMultiStop",
    FreeLegSpecialEquipmentTippingChassis = "FreeLegSpecialEquipmentTippingChassis",
    FreeLegSpecialEquipmentSideLoader = "FreeLegSpecialEquipmentSideLoader",
    Custom = "Custom",
    FreeLegDiesel = "FreeLegDiesel",
    FreeLegCongestion = "FreeLegCongestion",
    FreeLegToll = "FreeLegToll",
}

export enum SurchargeBaseUnit {
    Booking = "Booking",
    LoadingUnit = "LoadingUnit",
    TwentyFootEquivalentUnit = "TwentyFootEquivalentUnit",
    LoadingUnitDistanceInKm = "LoadingUnitDistanceInKm",
    LoadingUnitTollDistanceInKm = "LoadingUnitTollDistanceInKm",
}

export enum SurchargeOwnerType {
    RELATION = "RELATION",
    COMPANY = "COMPANY",
}

export enum TerminalTransportCarrier {
    RAIL = "RAIL",
    ROAD = "ROAD",
    VESSEL = "VESSEL",
    BARGE = "BARGE",
    UNKNOWN = "UNKNOWN",
}

export enum Title {
    Mr = "Mr",
    Mrs = "Mrs",
    Undefined = "Undefined",
}

export enum ToggleAction {
    toggleOff = "toggleOff",
    toggleOn = "toggleOn",
}

export enum TransportCarrier {
    S = "S",
    R = "R",
    F = "F",
    B = "B",
}

export enum TransportDirectionType {
    OneWay = "OneWay",
    RoundTrip = "RoundTrip",
}

export enum TransportMode {
    pedestrian = "pedestrian",
    car = "car",
    truck = "truck",
    bicycle = "bicycle",
}

export enum TypeSystemAction {
    create = "create",
    validate = "validate",
    update = "update",
}

export enum UserRole {
    SystemAdmin = "SystemAdmin",
    Dispatcher = "Dispatcher",
    Admin = "Admin",
    Anonymous = "Anonymous",
    ApiClient = "ApiClient",
    OrganizationAdmin = "OrganizationAdmin",
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}
